import React, {Fragment, useState, useEffect,  useRef  } from "react";

import {
  Box,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DateIcon from "@mui/icons-material/CalendarToday";
import TimeIcon from "@mui/icons-material/AccessTime";
import ButtonTypeOne from "../../buttons/TypeOne";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CreateIcon from "@mui/icons-material/Create";
import KeyIcon from "@mui/icons-material/Key";
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAppContext } from '../../../AppContext';
import { useTheme } from '@mui/material/styles';

import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function TRDesign2({trData}) {  
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState();

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    let custom_dates = siteData ? JSON.parse(siteData.club_inner[0].onelink_data) : null;
    setThemeCss(custom_css);
    setTableData(custom_dates);
    setLoading(false)
  }, [themeData]);
  


  const {
    date,
    setDate,
    // time0,
    // setTime0,
    noOfPersons,
    setNoOfPersons,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    comments,
    setComments,
    error,
    setError,
    otp,
    setOtp,
    success,
    setSuccess,
    sendOtp,
    verifyOtp,
    submitting,
  } = trData;

  const [showResendOtp, setshowResendOtp] = useState(false);
  const [timer, settimer] = useState(61);

  const resendOtp = () => sendOtp();

  useEffect(() => {
    step === 3 &&
      (timer > 0
        ? setTimeout(() => settimer(timer - 1), 1000)
        : setshowResendOtp(true));
  }, [timer]);

  const formContainerTwo = {
    width: "100%",
    // height:'100px',
    background: "#fff",
    border: `1px solid #1D1D1D`,
    borderRadius: 5,
    textAlign: "center",
    p: 3,
    fontFamily: themeCss
      ? themeCss.theme_body_font.replace("+", " ")
      : "",
    color: "#000 !important"
  };

  const InputStyle = {
    fontSize: `14px !important`,
    outline: "none",
    "& focus": {
      outline: "none",
    },
    fontFamily: themeCss
      ? themeCss.theme_body_font.replace("+", " ")
      : "",
  };

  const controlLabel = {
    fontWeight: 700,
    fontSize: 14,
    textAlign: "left",
    margin: {md:"0 15px 0 0 ",sm:"5px 0px 0 0", xs:"5px 0px 0 0"},
    color: "#2A2A2A",
    fontFamily: themeCss
      ? themeCss.theme_body_font.replace("+", " ")
      : "",
    alignSelf: "baseline",
    display: "flex",
    flexDirection: "row",
    mb: {md:2},
  };

  const iconStyle = {
    fontSize: 14,
    mr: 1,
    background: themeCss ? themeCss.theme_main_color + "15" : "#ff5500",
    width: 25,
    height: 25,
    p: 0.5,
    borderRadius: 5,
    alignSelf: "center",
  };

  const formWrapperFive = {
    "& h5": {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "",
      color: "#2A2A2A",
    },
    "& h6": {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "",
      color: "#2A2A2A",
    },
  };

  const BtnItem = {
    borderRadius: 2,
    padding: 0.5,
    m:0.3,
    background: '#efefef', 
    color:"#000",       
    minWidth: 50,
    height: 50,  
    "&:hover": {
      backgroundColor: themeCss ? themeCss.theme_main_color : '',  
      color: themeCss ? themeCss.theme_primary_font_color : '#000',
    },
    "& h5": {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "",
    },
    "& h6": {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "",
    },
  };
  const BtnItemActive = {
    backgroundColor: themeCss ? themeCss.theme_main_color : '',  
    color: themeCss ? themeCss.theme_primary_font_color : '#000',
    m:0.3,
    p: 0.5,
    minWidth: 50,
    height: 50,
    borderRadius: 2,
     "&:hover": {
      backgroundColor: themeCss ? themeCss.theme_main_color : '',  
      color: themeCss ? themeCss.theme_primary_font_color : '#000',
    },
    "& h5": {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "",
      color: themeCss ? themeCss.theme_primary_font_color : `#151414`,
    },
    "& h6": {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "",
      color: themeCss ? themeCss.theme_primary_font_color : `#151414`,
    }
  };

  const [showCommentsBox, setShowCommentsBox] = useState(false);
  const [step, setStep] = useState(1);
  const [timePickerModal, setTimePickerModal] = useState(false);

  const changeStep = (e, value) => {
    e.preventDefault();

    if (value == 1) {
      setError({
        msg: "",
        state: false,
      });
      setStep(value);
    }

    if (value == 2) {
      if (!time) {
        setError({
          msg: "Time is Invalid",
          state: true,
        });
      }else if (noOfPersons < 1) {
        setError({
          msg: "No Of Persons is Invalid",
          state: true,
        });
      } else {
        setError({
          msg: "",
          state: false,
        });
        setStep(value);
      }
    }

    if (value == 3) {
      const today = new Date().toString().slice(4, 15);
      const now = new Date().toString().slice(16, 21);

      if (name === "") {
        setError({
          msg: "Enter a Valid Name",
          state: true,
        });
      } else if (phone.length < 5) {
        setError({
          msg: "Enter a Valid Phone",
          state: true,
        });
      } else if (today === date) {
        if (time < now) {
          setError({
            msg: "Select a Valid Time",
            state: true,
          });
        } else {
          setError({
            msg: "",
            state: false,
          });
          setStep(value);
          sendOtp();
          settimer(60);
        }
      } else {
        setError({
          msg: "",
          state: false,
        });
        setStep(value);
        sendOtp();
        settimer(60);
      }
    }

    if (value == 4) {
      // console.log("here-----------");
      // console.log({ otp });
      if (otp.length !== 4) {
        setError({
          msg: "Enter a valid OTP",
          state: true,
        });
      } else {
        setError({
          msg: "",
          state: false,
        });
        verifyOtp();
      }
    }
  };

  const toggleCommentsBox = (e) => {
    e.preventDefault();
    setShowCommentsBox(!showCommentsBox);
  };

  const theme1 = createTheme({
  components: {
  MuiPickersDay:{
    styleOverrides:{
      root: {
        borderRadius: 8,
        padding: 12,
        background: '#efefef',          
        ":hover": {
          backgroundColor: themeCss ? themeCss.theme_main_color : '',  
          color: themeCss ? themeCss.theme_primary_font_color : '#000',
        },          
        "&.Mui-selected":{
          backgroundColor: themeCss ? themeCss.theme_main_color: '',  
          color: themeCss ? themeCss.theme_primary_font_color : '#000',          
          ":hover": {
            backgroundColor: themeCss ? themeCss.theme_main_color : '',  
            color: themeCss ? themeCss.theme_primary_font_color : '#000',
          },
          ":focus": {
            backgroundColor: themeCss ? themeCss.theme_main_color : '', 
            color: themeCss ? themeCss.theme_primary_font_color : '#000',
          },
        }
      }
    }
  },         
  MuiClockNumber:{
    styleOverrides:{
      root: {       
        borderRadius: 8,
        ":hover": {
          backgroundColor: themeCss ? themeCss.theme_main_color : '',  
          color: themeCss ? themeCss.theme_primary_font_color : '#000',
        },          
        "&.Mui-selected":{
          backgroundColor: themeCss ? themeCss.theme_main_color: '',  
          color: themeCss ? themeCss.theme_primary_font_color : '#000',          
          ":hover": {
            backgroundColor: themeCss ? themeCss.theme_main_color : '',  
            color: themeCss ? themeCss.theme_primary_font_color : '#000',
          },
          ":focus": {
            backgroundColor: themeCss ? themeCss.theme_main_color : '', 
            color: themeCss ? themeCss.theme_primary_font_color : '#000',
          },
        }
      }
    }
  }, 
  MuiClockPointer:{
    styleOverrides:{
      root: {         
        backgroundColor: themeCss ? themeCss.theme_main_color: '',
        ":hover": {
          backgroundColor: themeCss ? themeCss.theme_main_color : '',  
          color: themeCss ? themeCss.theme_primary_font_color : '#000',
        },          
      }
    }
    },
  MuiClock:{
    styleOverrides:{
      pin: {                    
        backgroundColor: themeCss ? themeCss.theme_main_color: '',                    
      }
    }
  },
  MuiButton:{
    styleOverrides:{
      root: {                    
        color: themeCss ? themeCss.theme_main_color: '',                    
      }
    }
  },
},
  });  

  const getTimeSlots = (start, end) => {  
    let hours = parseInt(start);
    let mins = parseInt(start);
    const mins2 = Math.round(mins / 30) * 30;
    const startNew = `${hours}:${mins2}`;

    let hours2 = parseInt(end);
    let mins21 = parseInt(end);
    const mins22 = Math.round(mins21 / 30) * 30;
    const endNew = `${hours2}:${mins22}`;

    var startTime = moment(startNew, "HH:mm");
    var endTime = moment(endNew, "HH:mm");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }

    var timeStops = [];
    const current_date = new Date();
    const current_time = `${new Date().getHours()}:${new Date().getMinutes()}`;

    while (startTime <= endTime) {
      timeStops.push(new moment(startTime).format("HH:mm"));
      startTime.add(30, "minutes");
    }

    const final = timeStops.filter((e) => {
      if (current_date == date) {
        return e >= current_time;
      } else {
        return e;
      }
    });

    final.pop();    

    if (moment(current_date).format('YYYY-mm-dd') == moment(date).format('YYYY-mm-dd')) {
      if (end >= current_time) {
        final.push(end);
      }
    } else {
      final.push(end);
    }
    return final;
  };

  const [times, setTimes] = useState([])
  const [time, setTime] = useState(times ? times[0] : null);  

  useEffect(() => {
    let day = moment(date).format('ddd');
    let start_time = '';
    let end_time = '';
    if (tableData && tableData.tableReservationsTimings) {
      for (let i = 0; i < 7; i++) {
        if (tableData.tableReservationsTimings[i].day == day) {
          start_time = tableData.tableReservationsTimings[i].start_time
          end_time = tableData.tableReservationsTimings[i].end_time
        }
      }
    }
    setTimes(getTimeSlots(start_time, end_time));
  }, [date, times])
  

  useEffect(() => {
    console.log(window.location.host);
    setError(error);
  }, [date, time, error]);

  const showTimePickerModal = () => {
    setTimePickerModal(true)
  }
   const closeTimePickerModal = () => {
    setTimePickerModal(false)
  }

  const setDate2 = (e) => {
    setDate(e);
    setTime('')
  }

  const stepOne = () => {
    return (
      <>
      <Box
        component="form"
        sx={{
          ...formContainerTwo,
          display: "flex",
          alignItems: "center",
          flexDirection:{md:"row",sm:"column", xs:"column"},
          "& .MuiTextField-root": {
            margin:{md:"0 15px 0 0", sm:"5px 0", xs:"5px 0"},
            width: {md:"100%",sm:"100%",xs:"100%"},
            pr:2,
            outline: "none",
            color: "#000 !important",
            "&.MuiOutlinedInput-root": {
              border: "none",
              borderRadius: 2,
              outline: "none",
              fontSize: 14,
              py: 0.4,
              px: 1,
              color: "#000 !important",
              fontweight:500,
              fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
            },
            "& .MuiOutlinedInput-root":{
              color: "#000 !important",
              '& .MuiOutlinedInput-notchedOutline':{
                borderColor: '#c4c4c4'
              }
            }
          },
        }}
        noValidate
        autoComplete="off"
      >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme1}>
          <Typography component="div" sx={{width:'100%'}}>
            <InputLabel for="selectDate" sx={controlLabel}>
              <DateIcon sx={iconStyle} />
              Select Date
          </InputLabel>
          <MobileDatePicker
            disablePast
            value={date}
            onChange={(e) => setDate2(e)}                            
            format={"DD MMM"}
            sx={{
              ...InputStyle,
              "& input ": {
                p: `10px !important`,
              },
              "& .MuiIconButton-root":{
                fontSize: 14,
                color: `#7B7B7B !important`,
                alignSelf: "center",
              }
            }}              
          />            
          </Typography>
          <Typography component="div" sx={{width:'100%'}}>
            <InputLabel for="selectTime" sx={controlLabel}>
              <TimeIcon sx={iconStyle} />
              Select Time
            </InputLabel>
            <TextField
              label=""
              type="text"
              id="time"
              variant="outlined"
              placeholder="00:00"
              sx={{
                ...InputStyle,
                "& input ": {
                  height:'23px',
                  p: `10px !important`,
                  width:'100%',
                  fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
                  color: "#000 !important",
                },
                "& .MuiOutlinedInput-root":{
                  color: "#000 !important",
                  '& .MuiOutlinedInput-notchedOutline':{
                    borderColor: '#c4c4c4'
                  }
                }
              }}            
              size="small"
              value={time}
              onClick={showTimePickerModal}
              onKeyDown={showTimePickerModal}
            />
                
          </Typography>
        </ThemeProvider>
      </LocalizationProvider>
        <Typography component="div" sx={{width:'100%'}}>
          <InputLabel for="noOfPersons" sx={controlLabel}>
            <PersonIcon sx={iconStyle} /> No Of Persons
          </InputLabel>
          <TextField
            label=""
            type="number"
            id="noOfPersons"
            variant="outlined"
            placeholder="No of Persons"
            sx={{
              ...InputStyle,
              "& input ": {
                height:'23px',
                p: `10px !important`,
                width:'100%',
                color: "#000 !important",
                fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
              },
              "& .MuiOutlinedInput-root":{
                color: "#000 !important",
                '& .MuiOutlinedInput-notchedOutline':{
                  borderColor: '#c4c4c4'
                }
              }
            }}
            InputProps={{
              // endAdornment: (
              //   <InputAdornment position="end">
              //     <PersonIcon sx={{...InputStyle,fontSize:18}} />
              //   </InputAdornment>
              // ),
              max: 20,
              min: 1
            }}
            size="small"
            value={noOfPersons ? noOfPersons : 1}
            onChange={(e) => setNoOfPersons(e.target.value >= 1 && e.target.value <= 20 ? e.target.value : noOfPersons)}
          
          />
        </Typography>
        <Typography component="div" onClick={(e) => changeStep(e, 2)}>
          <InputLabel for="noOfPersons" sx={controlLabel}>
            &nbsp;
          </InputLabel>
          <ButtonTypeOne text="Next" link="" borderRadius={2} />
        </Typography>
        
        </Box>
        
        <Dialog
          open={timePickerModal}
          onClose={closeTimePickerModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='xs'
          sx={{
            '& .MuiPaper-root':{
                background:'#fff !important',
                '& h2':{
                  color: `${theme.palette.background.default} !important`,
                  fontFamily: themeCss ? themeCss.theme_primary_font.replace("+", " "): "",
                }
            }
          }}
          // fullWidth={true}
        >
          <DialogTitle sx={{
            fontSize: 12,
            fontWeight: 100,
            color: `rgba(0, 0, 0, 0.6)`,
            textTransform: 'uppercase',
            letterSpacing: 1
          }}>Select Time</DialogTitle>
          <DialogContent>
            <Typography variant="h2" sx={{fontSize:`2.215rem`, fontWeight:400,  mb:4}}>{moment(date).format('ddd MMM, DD')}</Typography>
            <DialogContentText id="alert-dialog-description">
             {times.map(
                (e, i) =>
                  e !== null && (                          
                      <Button
                        sx={time === e || (i == 0 && !time) ? BtnItemActive : BtnItem}
                        value={time}
                        onClick={() => setTime(e)}
                      >
                        <Typography variant="h6" onClick={() => {
                          setTime(e);
                        }}>{e}</Typography>
                      </Button>                          
                    )
                )}            
            </DialogContentText>
          </DialogContent>
          <DialogActions>            
            <Button onClick={closeTimePickerModal} autoFocus sx={{ color: themeCss ? themeCss.theme_main_color : ''}}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        

      <>{error.state && (
        <Typography
          variant="p"
          sx={{ mt: 3, display: "block", fontSize: "13px", color: 'red' }}
        >
          {error.msg}
        </Typography>
      )}</>
      </>
    );
  };

  const stepTwo = () => {
    return (
      <Typography
        component="div"
        sx={{
          ...formContainerTwo,
          width:'100%'
        }}
      >
        <Box
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection:{md:"row",sm:"column", xs:"column"},
            "& .MuiTextField-root": {
              margin: {md:"0 15px 0 0", sm:"5px 0",xs:"5px 0"},
              width: {md:"100%",sm:"100%",xs:"100%"},
              pr:2,
              outline: "none",
              "& .MuiOutlinedInput-root": {
                border: "none",
                borderRadius: 2,
                outline: "none",
                fontSize: 12,
                py: 0.4,
                px: 1,
                color: "#000 !important",
                fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
                fontWeight:500
              },
              "& .MuiOutlinedInput-root":{
                color: "#000 !important",
                '& .MuiOutlinedInput-notchedOutline':{
                  borderColor: '#c4c4c4'
                }
              }
            },
          }}
          noValidate
          autoComplete="off"
        >
          
          <Typography component="div" className="mobileMode">
            <InputLabel for="noOfPersons" sx={{ ...controlLabel }}>
            <Stack
              sx={formWrapperFive}
              diaplay="flex"
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              
            >
              <Typography variant="h6">
                {moment(date).format('ddd DD')}&nbsp;&nbsp;|&nbsp;&nbsp;
              </Typography>
              <Typography variant="h6">
                {time}&nbsp;&nbsp;|&nbsp;&nbsp;
              </Typography>
              <Typography variant="h6">{noOfPersons} Person(s)</Typography>
            </Stack>
          </InputLabel> 
          </Typography>
                
          <Stack flexDirection={{md:'row',sm:'column', xs:'column'}} sx={{width:'100% !important'}}>
            <Typography component="div" sx={{width:'100% !important'}}>
              <InputLabel for="noOfPersons" sx={controlLabel}>
                <PersonIcon sx={iconStyle} /> Name
              </InputLabel>
              <TextField
                // label="Name"
                id="noOfPersons"
                variant="outlined"
                placeholder="Name"
                sx={{
                  ...InputStyle,
                  "& input ": {
                    height: '30px',
                    fontWeight:700,
                    p: `10px !important`,
                    width:'100%',
                    color: "#000 !important",
                    fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
                  },
                  "& .MuiOutlinedInput-root":{
                    color: "#000 !important",
                    '& .MuiOutlinedInput-notchedOutline':{
                      borderColor: '#c4c4c4'
                    }
                  }
                }}
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Typography>
            <Typography component="div" sx={{width:'100% !important'}}>
              <InputLabel for="email" sx={controlLabel}>
                <EmailIcon sx={iconStyle} /> Email
              </InputLabel>
              <TextField
                // label="Email"
                id="email"
                variant="outlined"
                placeholder="Email"
                sx={{
                  ...InputStyle,
                  "& input ": {
                    height: '30px',
                    fontWeight:700,
                    p: `10px !important`,
                    width:'100%',
                    color: "#000 !important",
                    fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
                  },
                  "& .MuiOutlinedInput-root":{
                    color: "#000 !important",
                    '& .MuiOutlinedInput-notchedOutline':{
                      borderColor: '#c4c4c4'
                    }
                  }
                }}
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Typography>
            <Typography component="div" sx={{width:'100% !important'}}>
              <InputLabel for="noOfPersons" sx={controlLabel}>
                <LocalPhoneIcon sx={iconStyle} /> Phone
              </InputLabel>
              {/* <Box className="mobile_field_light" sx={{  ...reactInputNew,  mr: {md:2, sm:0, xs:0} }}>
                <PhoneInput
                  international={true}
                  withCountryCallingCode={true}
                  limitMaxLength={true}
                  countryCallingCodeEditable={false}
                  defaultCountry="IN"
                  smartCaret={true}
                  placeholder="Mobile"
                  value={phone}
                  onChange={setPhone}
                  sx={{
                    ...InputStyle,
                    "& input ": {
                      height:'30px',
                      p: `10px !important`,
                      width:'100%',
                      fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
                    },
                  }}
                />
              </Box> */}
              <TextField
                id="phone"
                variant="outlined"
                placeholder="Mobile"
                maxLength="10"
                size="small"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                sx={{
                  ...InputStyle,
                  "& input ": {
                    height:'30px',
                    p: `10px !important`,
                    width:'100%',
                    fontWeight:700,
                    color: "#000 !important",
                    fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
                  },
                  "& .MuiOutlinedInput-root":{
                    color: "#000 !important",
                    '& .MuiOutlinedInput-notchedOutline':{
                      borderColor: '#c4c4c4'
                    }
                  }
                }}
              />
            </Typography>
            <Typography component="div" className="mobileMode">
            {!showCommentsBox ? (
              <Typography component="div" sx={{my:1}}>
              <InputLabel
                sx={{ ...controlLabel, mt: 1, cursor: "pointer", alignItems:'center', display:'flex'}}
                onClick={toggleCommentsBox}
              >
                <AddIcon
                  sx={{
                    fontSize: 13,
                    color: themeCss ? themeCss.theme_main_color : `#151414`,
                  }}
                />
                &nbsp;Special Instructions
              </InputLabel>
            </Typography>
            ):(
              <Typography component="div" sx={{my:1}}>
                <TextField
                  id="comments"
                  placeholder="Special Instructions"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  variant="outlined"
                  size="small"
                  // multiline
                  // maxRows={3}
                  sx={{
                    // mt:2,
                    // width: "30ch",
                    fontSize: `14px !important`,
                    fontFamily: themeCss
                      ? themeCss.theme_body_font.replace("+", " ")
                      : "",
                    ...InputStyle,
                    "& input ": {
                      height:'30px',
                      color: "#000 !important",
                      fontWeight:700,
                      p: `10px !important`,
                      width:'100%',
                      fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
                      
                    },
                    "& .MuiOutlinedInput-root":{
                      color: "#000 !important",
                      '& .MuiOutlinedInput-notchedOutline':{
                        borderColor: '#c4c4c4'
                      }
                    }
                  }}
                />
                </Typography>
              )}
          </Typography>
          </Stack>
            <Typography component="div" className="desktopMode">
              <Stack flexDirection={'column'} alignItems={'center'}>
                <Typography component="div">
                  <InputLabel for="noOfPersons" sx={{ ...controlLabel }}>
                  <Stack
                    sx={formWrapperFive}
                    diaplay="flex"
                    flexDirection={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    
                  >
                    <Typography variant="h6">
                      {moment(date).format('ddd DD')}&nbsp;&nbsp;|&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="h6">
                      {time}&nbsp;&nbsp;|&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="h6">{noOfPersons} Person(s)</Typography>
                  </Stack>
                </InputLabel> 
                </Typography>
                <Typography component="div" onClick={(e) => changeStep(e, 3)}>
                  <ButtonTypeOne text="Next" link="" borderRadius={2} style={{'min-width':'150px'}}/>
                </Typography>
              </Stack>
            </Typography>
          <Typography component="div" className="mobileMode" sx={{width:'100%'}}>
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography component="div">
                <InputLabel
                  sx={{ ...controlLabel, mt: 1, cursor: "pointer" , margin:{md:'0', sm:'0 auto', xs:'0 auto'}}}
                  onClick={(e) => changeStep(e, 1)}
                >
                  <KeyboardBackspaceIcon
                    sx={{
                      fontSize: 13,
                      color: `#2e2e2e`,
                    }}
                  />
                  &nbsp;Back
                </InputLabel>
              </Typography>
              <Typography component="div" onClick={(e) => changeStep(e, 3)}>
                <ButtonTypeOne text="Next" link="" borderRadius={2} style={{'min-width':'150px'}}/>
              </Typography>
            </Stack>
          </Typography>
        </Box>
        <Typography
          component="div"
          className="desktopMode"
        >
          <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{mt:2}}>            
            <Typography component="div" sx={{display:'flex',alignItems:'center', width:'100%'}}>
            {!showCommentsBox ? (
              <InputLabel
                sx={{ ...controlLabel, mt: 1, cursor: "pointer" }}
                onClick={toggleCommentsBox}
              >
                <AddIcon
                  sx={{
                    fontSize: 13,
                    color: themeCss ? themeCss.theme_main_color : `#151414`,
                  }}
                />
                &nbsp;Special Instructions
              </InputLabel>
            ):(
                <TextField
                  id="comments"
                  placeholder="Special Instructions"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  variant="outlined"
                  size="small"
                  // multiline
                  // maxRows={3}
                  sx={{
                    width: {md:"54%",sm:'100%', xs:'100%'},
                    ...InputStyle,
                    "& input ": {
                      height:'30px',
                      p: `10px !important`,
                      width:'100%',
                      color: "#000 !important",
                      fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
                    },
                    "& .MuiOutlinedInput-root":{
                      color: "#000 !important",
                      '& .MuiOutlinedInput-notchedOutline':{
                        borderColor: '#c4c4c4'
                        
                      }
                    }
                  }}
                />
              )}
            </Typography>
            <Typography component="div">
              <InputLabel
                sx={{ ...controlLabel, mt: 1, cursor: "pointer" , margin:{md:'0', sm:'0 auto', xs:'0 auto', alignItems:'center'}}}
                onClick={(e) => changeStep(e, 1)}
              >
                <KeyboardBackspaceIcon
                  sx={{
                    fontSize: 13,
                    color: `#2e2e2e`,
                  }}
                />
                &nbsp;Back
              </InputLabel>
            </Typography>
          </Stack>
        </Typography>
        {error.state && (
          <Typography
            variant="p"
            sx={{ mt: 3, display: "block", fontSize: "13px", color: "red" }}
          >
            {error.msg}
          </Typography>
        )}
      </Typography>
    );
  };

  const stepThree = () => {
    return (
      <Typography
        component="div"
        sx={{
          ...formContainerTwo,
          width:{md:'50%',sm:'100%',xs:'100%'},
          margin:'0 auto'
        }}
      >
        <Box
          component="form"
          sx={{
            // ...formContainerTwo,
            display: "flex",
            alignItems: "center",
            flexDirection:{md:"row",sm:"column", xs:"column"},
            "& .MuiTextField-root": {
              margin: {md:"0 15px 0 0 ", sm:"5px 0",xs:"5px 0"},
              width: {md:"100%",sm:"100%",xs:"100%"},
              pr:2,
              outline: "none",
              "& .MuiOutlinedInput-root": {
                border: "none",
                borderRadius: 2,
                outline: "none",
                fontSize: 12,
                py: 0.4,
                px: 1,
                color: "#000 !important",
                fontWeight:500,
                fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
              },
              "& .MuiOutlinedInput-root":{
                color: "#000 !important",
              '& .MuiOutlinedInput-notchedOutline':{
                borderColor: '#c4c4c4'
              }
            }
            },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography component="div" className="mobileMode">
            <InputLabel for="noOfPersons" sx={{ ...controlLabel }}>
            <Stack
              sx={formWrapperFive}
              diaplay="flex"
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              
            >
              <Typography variant="h6">
                {moment(date).format('ddd DD')}&nbsp;&nbsp;|&nbsp;&nbsp;
              </Typography>
              <Typography variant="h6">
                {time}&nbsp;&nbsp;|&nbsp;&nbsp;
              </Typography>
              <Typography variant="h6">{noOfPersons} Person(s)</Typography>
            </Stack>
          </InputLabel> 
          </Typography>
          <Stack flexDirection={"row"} sx={{width:'100%'}}>
            <Typography component="div" sx={{width:'100%'}}>
              <InputLabel for="noOfPersons" sx={controlLabel}>
                <KeyIcon sx={iconStyle} /> OTP
              </InputLabel>
              <TextField
                fullWidth
                type="number"
                id="otp"
                variant="outlined"
                placeholder="OTP"
                sx={InputStyle}
                size="small"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </Typography>
          </Stack>
          {/* onClick={(e) => changeStep(e, 3)} */}
          <Typography component="div">
            <Typography component="div"  className="desktopMode">
              <InputLabel for="noOfPersons" sx={{ ...controlLabel }}>
                <Stack
                  sx={formWrapperFive}
                  diaplay="flex"
                  flexDirection={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Typography variant="h6">
                    {moment(date).format('ddd DD')}&nbsp;&nbsp;|&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="h6">
                    {time}&nbsp;&nbsp;|&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="h6">{noOfPersons} Person(s)</Typography>
                </Stack>
              </InputLabel>
            </Typography>
            <Box onClick={(e) => changeStep(e, 4)} sx={{mt:2}}>
              <ButtonTypeOne
                text={submitting ? "Submitting" : "Submit"}
                link=""
                borderRadius={2}
              />
            </Box>
          </Typography>
        </Box>
        <Typography
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            alignItems:'center'
          }}
        >
          <InputLabel
            sx={{ ...controlLabel, mt: 1, cursor: "pointer", alignItems:'center' }}
            onClick={(e) => changeStep(e, 2)}
          >
            <KeyboardBackspaceIcon
              sx={{
                fontSize: 13,
                color: `#2e2e2e`,
              }}
            />
            &nbsp;Back
          </InputLabel>

          {showResendOtp ? (
            <Typography
              variant="text"
              sx={{ color: "#000", fontSize: "10px", cursor: "pointer" }}
              onClick={() => resendOtp()}
            >
              Resend
            </Typography>
          ) : (
            <Typography
              variant="text"
              sx={{
                fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
                color: "#000",
                fontSize: "10px",
              }}
            >
              {timer}
            </Typography>
          )}
        </Typography>
        {error.state && (
          <Typography
            variant="p"
            sx={{ mt: 3, display: "block", fontSize: "13px", color: "red" }}
          >
            {error.msg}
          </Typography>
        )}
      </Typography>
    );
  };

  return (
    <Stack
      diaplay="flex"
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ minHeight: "80vh", width:'100%' }}
    >
      <Typography component="div" sx={{width:'80%'}}>
        {success ? (
          <Typography
            variant="p"
            sx={{ my: 3, display: "block", fontSize: "13px", color: "green" }}
          >
            Thank you for submitting. We will get back to you with the
            confirmation soon.
          </Typography>
        ) : (
          <>
          {step == 3 ? (
            <Typography variant="h2" sx={{ textAlign: "center", mt:{md:2, sm:3, xs:4} }}>
              Reserve a table
            </Typography>
          ):(
            <Typography variant="h2" sx={{ textAlign: {md:'left', sm:'center', xs:'center'}, mt:{md:2, sm:3, xs:4} }}>
              Reserve a table
            </Typography>
          )}

            {step === 1 && stepOne()}
            {step === 2 && stepTwo()}
            {step === 3 && stepThree()}
          </>
        )}
      </Typography>
    </Stack>
  );
}
