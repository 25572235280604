import React from "react";
import Skeleton from "@mui/material/Skeleton";
import {Stack} from '@mui/material'

export default function Four () {
    return(
        <Stack sx={{display:'flex', justifyContent:'space-between', flexDirection:'row'}}>
            <Skeleton
                animation="wave"
                variant="rectangular"
                height={50}
                width={100}
                sx={{ borderRadius: 3 }}

            />
            <Skeleton
                animation="wave"
                variant="rectangular"
                height={300}
                width={400}
                sx={{ borderRadius: 4, ml:2}}
            />
        </Stack>
    )
}