import React, {Fragment, useEffect, useState } from "react";
import Head from 'next/head'
import axios from 'axios'
import Header from '@/components/common/Header'
import Header2 from '@/components/navbars/NavbarZero'
import HomeAboutUs from '@/components/HomeAboutUs'
import HomeEvents from '@/components/HomeEvents'
import HomeDeals from '@/components/HomeDeals'
import HomeGalleries from '@/components/HomeGalleries'
import HomeBreakSection from '@/components/breakImages/ThemeOne'
import HomeMenus from '@/components/HomeMenus'
import HappyHoursFoodOrderSections from '@/components/HappyHoursFoodOrderSections'
import Footer from '@/components/common/Footer'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Grid, Container, Typography } from '@mui/material';
import Skeleton from "@mui/material/Skeleton";
import { nanoid } from 'nanoid';
import PageLoader from '@/components/loader';

import EventSkeletonOne from '@/components/skeletons/events/One'
import EventSkeletonTwo from '@/components/skeletons/events/Two'
import EventSkeletonThree from '@/components/skeletons/events/Three'
import EventSkeletonFour from '@/components/skeletons/events/Four'
import EventSkeletonFive from '@/components/skeletons/events/Five'
import EventSkeletonSix from '@/components/skeletons/events/Six'

import DealSkeletonOne from '@/components/skeletons/deals/One'
import DealSkeletonTwo from '@/components/skeletons/deals/Two'
import DealSkeletonThree from '@/components/skeletons/deals/Three'
import DealSkeletonFour from '@/components/skeletons/deals/Four'
import DealSkeletonFive from '@/components/skeletons/deals/Five'
import DealSkeletonSix from '@/components/skeletons/deals/Six'
import { useAppContext } from '../AppContext';
import { useQuery } from 'react-query';
import { useTheme } from '@mui/material/styles'
import { server } from '../config'

export async function getServerSideProps(context) {
  context.res.setHeader(
    'Cache-Control',
    `public, s-maxage=1, stale-while-revalidate=${process.env.CACHE_DURATION}`
  )
  const whiteLabelUrl = context.req.headers.host;
  const url1 = `${server}/api/homePage/detailsNew?whiteLabelUrl=${whiteLabelUrl}`
  const response = await axios.get(url1, {
    headers: {
      'x-auth-token': process.env.JWT_TOKEN
    }
  });

  return {
    props: {
      url:whiteLabelUrl,
      ogData: response.data ? response.data : null
    }
  }
}

const renderEventSkeleton = (type) => {
  switch(type){
    case 1: return <EventSkeletonOne />;
    case 2: return <EventSkeletonTwo />;
    case 3: return <EventSkeletonThree />;
    case 4: return <EventSkeletonFour />;
    case 5: return <EventSkeletonFive />;
    case 6: return <EventSkeletonSix />;
    default: return <EventSkeletonOne />;
  };
};

const renderDealSkeleton = (type) => {
  switch(type){
    case 1: return <DealSkeletonOne />;
    case 2: return <DealSkeletonTwo />;
    case 3: return <DealSkeletonThree />;
    case 4: return <DealSkeletonFour />;
    case 5: return <DealSkeletonFive />;
    case 6: return <DealSkeletonSix />;
    default: return <DealSkeletonOne />;
  };
};

export default function Home(props) { 

  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [themeCss, setThemeCss] = useState(null);
  const [ogData, setOgData] = useState(props.ogData);
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();

  useEffect(()=>{
    changeUrl(props.url)
  },[url]);

  const styles = {
    body: {
      backgroundColor: theme.palette.background.default,
      contrastText: theme.palette.primary.contrastText,
    },
  };

  useEffect(()=>{
    AOS.init();
    AOS.refresh();
    document.body.style.backgroundColor = styles.body.backgroundColor;
    document.body.style.color = styles.body.contrastText;
  },[]);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css);
    setLoading(false)
  }, []);

  let skeletonCards1 = Array(10).fill(0);
  let skeletonCards2 = Array(3).fill(0);
  let skeletonCards3 = Array(2).fill(0);

  const fetchAboutUsData = async(clubId) => {
    const url = `/api/homePage/about?clubId=${clubId}`
    const response = await axios.get(url, {
      headers: {
        'x-auth-token': process.env.JWT_TOKEN
      }
    });
    return response.data.aboutPageSections
  };

  const fetchEventsData = async(clubId) => {
    let url = `/api/homePage/events?clubId=${clubId}`
    if(ogData && ogData.club_inner[0].events_only == 1){
      let url = `/api/homePage/eventsAll?clubId=${clubId}`
    }
    
    const response = await axios.get(url, {
      headers: {
        'x-auth-token': process.env.JWT_TOKEN
      }
    });
    return response.data
  };

  const fetchDealsData = async(clubId) => {
    const url = `/api/homePage/deals?clubId=${clubId}`
    const response = await axios.get(url, {
      headers: {
        'x-auth-token': process.env.JWT_TOKEN
      }
    });
    return response.data
  };

  const fetchHappyHoursData = async(clubId) => {
    const url = `/api/homePage/clubInner?clubId=${clubId}`
    const response = await axios.get(url, {
      headers: {
        'x-auth-token': process.env.JWT_TOKEN
      }
    });
    return response.data;
  };

  const fetchGalleryData = async(clubId) => {
    const url = `/api/homePage/gallery?clubId=${clubId}`
    const response = await axios.get(url, {
      headers: {
        'x-auth-token': process.env.JWT_TOKEN
      }
    });
    return response.data.result
  };

  const fetchMenusData = async(clubId) => {
    const url = `/api/homePage/menus?clubId=${clubId}`
    const response = await axios.get(url, {
      headers: {
        'x-auth-token': process.env.JWT_TOKEN
      }
    });
    return response.data;
  };

  const AboutUsSection = () => {

    const { data: aboutUsPageSections, error: aboutUsError, isLoading: aboutUsLoading, isFetching: aboutUsFetching  } = useQuery(['HomeAboutUs', siteData && siteData.club_inner[0].clubId], () => fetchAboutUsData(siteData && siteData.club_inner[0].clubId),{
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval:false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      // staleTime: Infinity,
    });

    if (aboutUsLoading || aboutUsFetching ) return (
      <Container maxWidth="lg" sx={{my:5, py:5}}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={nanoid(30)}>
          {skeletonCards1.map((index) => (
              <Skeleton
                animation="wave"
                height={20}
                sx={{  mb:1.5 }}
                key={nanoid(30)}
              />
          ))}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={nanoid(30)}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={300}
                sx={{  borderRadius: 0 }}
              />
          </Grid>
        </Grid> 
      </Container>
    );

    if (aboutUsError) return <div>Error loading products</div>;
  
    return (
      <div id="section2">
        {siteData && siteData.sections[0].sections.split(",").indexOf('section2') >=0 && (
          <>
            {aboutUsPageSections && (
              <div data-aos="fade-up" data-aos-duration="1000" data-aos-offset="100">
                <HomeAboutUs data={aboutUsPageSections} url={props.url} />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const EventsSection = () => {
    const { data: eventsData, error: EventsError, isLoading: EventsLoading, isFetching: EventsFetching  } = useQuery(['HomeEvents',siteData && siteData.club_inner[0].clubId], () => fetchEventsData(siteData && siteData.club_inner[0].clubId),{
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval:false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      // staleTime: Infinity,
    });  

    if (EventsLoading || EventsFetching ) return (
      <Container maxWidth="lg" sx={{my:5, py:5}}>
        <Grid container spacing={3}>                
          {skeletonCards2.map(() => (
            <Grid item xs={12} sm={6}
              md={siteData && siteData.sections[0].events_type == 6 ? 6 : 4} 
              lg={siteData && siteData.sections[0].events_type == 6 ? 6 : 4} 
              xl={siteData && siteData.sections[0].events_type == 6 ? 6 : 4} 
              key={nanoid(30)}
            >
              {renderEventSkeleton(siteData && siteData.sections[0].events_type)}
            </Grid>
          ))} 
        </Grid> 
      </Container>
    );
    
    if (EventsError) return <div>Error loading section...</div>;

    console.log(EventsFetching+'---'+EventsLoading);
  
    return (
      <div id="section1">
        {siteData && siteData.sections[0].sections.split(",").indexOf('section1') >=0 && (
          <>
            {eventsData && (
              <div data-aos="fade-up" data-aos-duration="1000" data-aos-offset="100">
                <HomeEvents data={eventsData.upcoming_events} rdata={eventsData.recent_events} url={props.url} />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const DealsSection = () => {
    const { data: dealsData, error: DealsError, isLoading: DealsLoading, isFetching: DealsFetching  } = useQuery(['HomeDeals',siteData && siteData.club_inner[0].clubId], ()=>fetchDealsData(siteData && siteData.club_inner[0].clubId),{
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval:false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      // staleTime: Infinity,
    });  

    if (DealsLoading || DealsFetching ) return (
      <Container maxWidth="lg" sx={{my:5, py:5}}>
        <Grid container spacing={3}>                
          {skeletonCards2.map((index) => (
            <Grid item xs={12} sm={6}
            md={siteData && (siteData.sections[0].deals_type == 1 || siteData.sections[0].deals_type == 4) ? 4 : 6} 
            lg={siteData && (siteData.sections[0].deals_type == 1 || siteData.sections[0].deals_type == 4) ? 4 : 6} 
            xl={siteData && (siteData.sections[0].deals_type == 1 || siteData.sections[0].deals_type == 4) ? 4 : 6} 
            key={nanoid(30)}>
            {renderDealSkeleton(siteData && siteData.sections[0].deals_type)}
          </Grid>
          ))} 
        </Grid> 
      </Container>
    );
    
    if (DealsError) return <div>Error loading section...</div>;
  
    return (
      <div id="section6">
        {siteData && siteData.sections[0].sections.split(",").indexOf('section6') >=0 && (
          <> 
            {dealsData && (
              <div data-aos="fade-up" data-aos-duration="1000" data-aos-offset="100">
                <HomeDeals data={dealsData.deals} />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const HappyHoursSection = () => {
    const { data: happyHoursData, error: HappyHoursError, isLoading: HappyHoursLoading, isFetching: HappyHoursFetching  } = useQuery(['HomeHappyHoursData',siteData && siteData.club_inner[0].clubId], ()=>fetchHappyHoursData(siteData && siteData.club_inner[0].clubId),{
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval:false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      // staleTime: Infinity,
    });  

    if (HappyHoursLoading || HappyHoursFetching ) return (
      <Container maxWidth="lg" sx={{my:5, py:5}}>
        <Grid container spacing={3}>                
          {skeletonCards3.map((index) => (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={nanoid(30)}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={100}
                sx={{  borderRadius: 2 }}
              />
            </Grid>
          ))} 
        </Grid> 
      </Container>
    );
    
    if (HappyHoursError) return <div>Error loading section...</div>;
  
    return (
      <div id="section4">
        {siteData && siteData.sections[0].sections.split(",").indexOf('section4') >=0 && (
          <>
            {siteData.club_inner[0].happy_hours != '' && happyHoursData && (
              <HappyHoursFoodOrderSections 
                data1={happyHoursData.result1[0]} 
                data2={happyHoursData.result2[0]} 
                url={url}
              />
            )}
          </>
        )}
      </div>
    );
  };

  const GallerySection = () => {
    const { data: galleryData, error: GalleryError, isLoading: GalleryLoading, isFetching: GalleryFetching  } = useQuery(['HomeGalleryData',siteData && siteData.club_inner[0].clubId], ()=>fetchGalleryData(siteData && siteData.club_inner[0].clubId),{
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval:false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      // staleTime: Infinity,
    });  

    if (GalleryLoading || GalleryFetching ) return (
      <Container maxWidth="lg" sx={{my:5, py:5}}>
        <Grid container spacing={3}>                
          {skeletonCards2.map((index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={nanoid(30)}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={300}
                sx={{  borderRadius: 0 }}
              />
              <Skeleton
                animation="wave"
                variant="p"
                sx={{  mb:1.5 }}
              />
            </Grid>
          ))} 
        </Grid> 
      </Container>
    );
    
    if (GalleryError) return <div>Error loading section...</div>;
  
    return (
      <div id="section8">
        {siteData && siteData.sections[0].sections.split(",").indexOf('section8') >=0 && (
          <>
            {galleryData && (
              <div data-aos="fade-up" data-aos-duration="1000" data-aos-offset="100">
                <HomeGalleries data={galleryData} />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const MenusSection = () => {
    const { data: menusData, error: MenusDataError, isLoading: MenusDataLoading, isFetching: MenusFetching  } = useQuery(['HomeMenusData',siteData && siteData.club_inner[0].clubId], ()=>fetchMenusData(siteData && siteData.club_inner[0].clubId),{
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval:false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      // staleTime: Infinity,
    });  

    if (MenusDataLoading || MenusFetching ) return (
      <Container maxWidth="lg" sx={{my:5, py:5}}>
        <Grid container spacing={3}>                
          {skeletonCards3.map((index) => (
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={nanoid(30)}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={300}
                sx={{  borderRadius: 0 }}
              />
            </Grid>
          ))} 
        </Grid> 
      </Container>
    );
    
    if (MenusDataError) return <div>Error loading section...</div>;
  
    return (
      <div id="section3">
        {siteData && siteData.sections[0].sections.split(",").indexOf('section3') >=0 && (
          <>
            {menusData && (
              <div data-aos="fade-up" data-aos-duration="1000" data-aos-offset="100">
                <HomeMenus data1={menusData.result1} data2={menusData.result2} />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const BreakImageSection = () => {
    
    return (
      <>
      {siteData && siteData.sections[0].sections.split(",").indexOf('section5') >=0 && (
        <div data-aos="fade-up" data-aos-duration="1000" data-aos-offset="100">
          <HomeBreakSection /> 
        </div>
      )}
      </>
    );
  };

  return (
    <Fragment>    
      <Head>
          {ogData&&ogData.club_inner[0].is_event == 1 ?(
            <>
              <title>{ogData && ogData.club_inner[0].restaurantTitle ? ogData.club_inner[0].restaurantTitle : ''} - {ogData && ogData.club_inner[0].areaName} - Book Event Tickets | Home</title> 
              <meta property="og:title" content={`${ogData && ogData.club_inner[0].restaurantTitle} - ${ogData && ogData.club_inner[0].areaName} - Book Event Tickets`} />
              <meta property="og:description" content={`Get ready to party at ${ogData && ogData.club_inner[0].restaurantTitle}, the hottest spot in ${ogData && ogData.club_inner[0].areaName}. Book event tickets on our website. Join us for an unforgettable night of music, drinks, and non-stop excitement.`} />
            </>
          ):(
            <>
              <title>{ogData && ogData.club_inner[0].restaurantTitle ? ogData.club_inner[0].restaurantTitle : ''} - {ogData && ogData.club_inner[0].areaName} - Book Events, Deals & Tables | Home</title>  
              <meta property="og:title" content={`${ogData && ogData.club_inner[0].restaurantTitle} - ${ogData && ogData.club_inner[0].areaName} - Book Events, Deals & Tables`} />
              <meta property="og:description" content={`Get ready to party at ${ogData && ogData.club_inner[0].restaurantTitle}, the hottest spot in ${ogData && ogData.club_inner[0].areaName}. Book event tickets, reserve tables, and discover exclusive deals on our website. Join us for an unforgettable night of music, drinks, and non-stop excitement.`} />
            </> 
          )}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={ogData && ogData.club_inner[0].white_label_url ? ogData.club_inner[0].white_label_url : ''} />
          <meta property="og:image" content={ogData && ogData.sections[0].home_banner ? ogData.sections[0].home_banner : ''} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:site_name" content={ogData && ogData.club_inner[0].restaurantTitle} />
          <meta property="og:locale" content="en_IN" />
        </Head> 
      {themeLoading && siteLoading ? (
        <PageLoader />
      ):(
        <Typography component="div" sx={{minHeight:'90vh !important'}}>
          {ogData && ogData.club_inner[0].events_only == 1 ?(
            <Typography component="div" sx={{minHeight:'90vh !important'}}>
              <Header2 />
              <EventsSection />
            </Typography>
          ):(
            <>
              <Header />
              <AboutUsSection />
              <EventsSection />
              <DealsSection />
              <HappyHoursSection />
              <GallerySection />
              <MenusSection />
            </>
          )}
          <BreakImageSection data={siteData} />
          <Footer />
        </Typography>
      )}
    </Fragment>
  )
}
