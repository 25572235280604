import React, {useState, useEffect} from 'react';
import { Container, Typography, Grid, Stack } from '@mui/material';
import EventOne from './events/EventOne';
import EventTwo from './events/EventTwo';
import EventThree from './events/EventThree';
import EventFour from './events/EventFour';
import EventFive from './events/EventFive';
import EventSix from './events/EventSix';
import EventSeven from './events/EventSeven';
import { nanoid } from 'nanoid';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Link from 'next/link'
import { useAppContext } from '../AppContext';
import { useTheme } from '@mui/material/styles';

const renderEventCard = (type, event) => {
  switch(type){
    case 1: return <EventOne data={event} disableButton={false} />;
    case 2: return <EventTwo data={event} disableButton={false} />;
    case 3: return <EventThree data={event} disableButton={false} />;
    case 4: return <EventFour data={event} disableButton={false} />;
    case 5: return <EventFive data={event} disableButton={false} />;
    case 6: return <EventSix data={event} disableButton={false} />;
    case 7: return <EventSeven data={event} disableButton={false} />;
    default: return <EventOne data={event} disableButton={false} />;
  };
};

const renderEventCardNew = (type, event) => {
  switch(type){
    case 1: return <EventOne data={event} disableButton={true} />;
    case 2: return <EventTwo data={event} disableButton={true} />;
    case 3: return <EventThree data={event} disableButton={true} />;
    case 4: return <EventFour data={event} disableButton={true} />;
    case 5: return <EventFive data={event} disableButton={true} />;
    case 6: return <EventSix data={event} disableButton={true} />;
    case 7: return <EventSeven data={event} disableButton={true} />;
    default: return <EventOne data={event} disableButton={true} />;
  };
};

export default function HomeEvents(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);
    
  const HomeEventsContainer = {
    pt:{md:10, sm:7, xs:5},
      '& h2':{
        fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
        fontSize:35,
        fontWeight:700,
        color: theme.palette.primary.title
      },
      '& a':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:14,
      fontWeight:400,
      color: themeCss ? themeCss.theme_primary_font_color : "",
      "&:hover":{
        color: themeCss ? themeCss.theme_main_color : "",
      }
    }
  };

  return (
    <>
      {props && props.data && props.data.length ? (
        <Container maxWidth="lg" sx={HomeEventsContainer}>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center" sx={{pb:{md:5, sm:3, xs:3}}}>
            <Typography variant="h2">Upcoming Events</Typography>
            {siteData && siteData.club_inner[0].events_only == 0 ? (
              <Link href={`/events`} >View All</Link>
            ):('')}
          </Stack>
          <Typography component="div" className="desktopMode">
            <Grid container spacing={siteData && siteData.sections[0].events_type == 4 || siteData.sections[0].events_type == 6 ? 3: 5 }>
              {siteData && props.data.map((event, index) => (
                <>
                  {index <= 5 ? (
                    <> 
                    {
                      siteData && siteData.sections[0].events_type == 1 || 
                      siteData && siteData.sections[0].events_type == 2 || 
                      siteData && siteData.sections[0].events_type == 3 ||
                      siteData && siteData.sections[0].events_type == 4 ||
                        siteData && siteData.sections[0].events_type == 5 ? (
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={nanoid(30)}>
                              {renderEventCard(siteData && siteData.sections[0].events_type, event)}
                          </Grid>
                        ):(
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={nanoid(30)}>
                              {renderEventCard(siteData && siteData.sections[0].events_type, event)}
                          </Grid>
                        )}
                    </>
                  ):('')}
                </>
              ))}
            </Grid>
          </Typography>
            <Typography component="div" className="mobileMode">
              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={20}                                                   
                navigation                        
                autoplay={true}
                loop={true}
                slidesPerView={siteData && siteData.sections[0].events_type == 6 ? 1: 1.15}
                breakpoints={{
                  768: {
                      width: 768,
                      slidesPerView: 2,
                  },
                  425: {
                      width: 425,
                      slidesPerView: 1.25,
                  },
                }}
              >
                {props.data && props.data.map((event) => (
                  <>
                    {
                    siteData && siteData.sections[0].events_type == 1 || 
                    siteData && siteData.sections[0].events_type == 2 || 
                    siteData && siteData.sections[0].events_type == 3 ||
                    siteData && siteData.sections[0].events_type == 4 ||
                      siteData && siteData.sections[0].events_type == 5 ? (
                        <SwiperSlide nanoid={30}>
                          {renderEventCard(siteData && siteData.sections[0].events_type, event)}
                        </SwiperSlide>
                    ):(
                      <SwiperSlide nanoid={30}>
                        {renderEventCard(siteData && siteData.sections[0].events_type, event)}
                      </SwiperSlide>
                    )}
                    </>
                  ))}
                </Swiper>
            </Typography>
        </Container>
      ):(
        <>
        {props && props.rdata && props.rdata.length ? (
          <Container maxWidth="lg" sx={HomeEventsContainer}>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" sx={{pb:{md:5, sm:3, xs:3}}}>
              <Typography variant="h2">Recent Events</Typography>
            </Stack>
            <Typography component="div" className="desktopMode">
              <Grid container spacing={siteData && siteData.sections[0].events_type == 4 || siteData.sections[0].events_type == 6 ? 3: 5 }>
                {siteData && props.rdata.map((event, index) => (
                  <>
                    {index <= 5 ? (
                      <> 
                      {
                        siteData && siteData.sections[0].events_type == 1 || 
                        siteData && siteData.sections[0].events_type == 2 || 
                        siteData && siteData.sections[0].events_type == 3 ||
                        siteData && siteData.sections[0].events_type == 4 ||
                          siteData && siteData.sections[0].events_type == 5 ? (
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                {renderEventCardNew(siteData && siteData.sections[0].events_type, event)}
                            </Grid>
                        ):(
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                {renderEventCardNew(siteData && siteData.sections[0].events_type, event)}
                            </Grid>
                        )}
                      </>
                    ):('')}
                  </>
                ))}
              </Grid>
            </Typography>
            <Typography component="div" className="mobileMode">
              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={20}                                                   
                navigation                        
                autoplay={true}
                loop={true}
                slidesPerView={siteData && siteData.sections[0].events_type == 6 ? 1: 1.15}
                breakpoints={{
                  768: {
                      width: 768,
                      slidesPerView: 2,
                  },
                  425: {
                      width: 425,
                      slidesPerView: 1.25,
                  },
                }}
              >
                {props.rdata && props.rdata.map((event) => (
                  <>
                    {
                    siteData && siteData.sections[0].events_type == 1 || 
                    siteData && siteData.sections[0].events_type == 2 || 
                    siteData && siteData.sections[0].events_type == 3 ||
                    siteData && siteData.sections[0].events_type == 4 ||
                      siteData && siteData.sections[0].events_type == 5 ? (
                        <SwiperSlide nanoid={30}>
                          {renderEventCardNew(siteData && siteData.sections[0].events_type, event)}
                        </SwiperSlide>
                    ):(
                      <SwiperSlide nanoid={30}>
                        {renderEventCardNew(siteData && siteData.sections[0].events_type, event)}
                      </SwiperSlide>
                    )}
                    </>
                  ))}
                </Swiper>
            </Typography>
          </Container>
        ):("")}
        </>
      )}
    </>
  );
}
