import React, {useState, useEffect, useContext} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MenuImage from '../../public/images/menu.png'
import Image from 'next/image'
import Link from 'next/link'
import EastIcon from '@mui/icons-material/East';
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';

export default function MenuOne({title, data1, data2}) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const MenuCardOne = {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background: theme.palette.background.alternate,
    boxShadow:0,
    borderRadius:0,
    width:'100%',
    borderRadius:5,
    border:`1px solid ${theme.palette.primary.borderColor}`,
    p:1,
    pr:2,
    mb:2,
    boxShadow: theme.shadows[0],
    transition:`0.5s`,
    "&:hover":{
      boxShadow: theme.shadows[1],
      transition:`0.5s`,
    },
    '& img':{
        borderRadius:4,
        width:100,
        height:100,
        mb:0
    },
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color:theme.palette.primary.contrastText,
      textTransform:'capitalize',
      pl:3
    }
}


  return (
    
    <Link href={`/menus`} >
      <Grid container spacing={0} sx={MenuCardOne}>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} alignSelf="center" textAlign="left" sx={{mb:0}}>
          {title == 'food menu' ? ( 
            <Image src={data1 ? data1 && data1[0].img_path : MenuImage} 
            width="50" 
            height="50" 
            alt="..."
            sizes="(max-width: 325px) 100vw"
            style={{objectFit: "cover"}}	
          />
          ):(
            <Image 
              src={data2 ? data2 && data2[0].img_path : MenuImage} 
              width="50" 
              height="50" 
              alt="..."
              sizes="(max-width: 325px) 100vw"
              style={{objectFit: "cover"}}	
          />
          )}
          </Grid>
          <Grid item xs={7} sm={7} md={7} lg={7} xl={7} alignSelf="center">
            <Typography variant="h3">{title}</Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} alignSelf="center" textAlign="right">
            <EastIcon style={{fontSize:22}} />
          </Grid>
      </Grid>
    </Link>
     
  );
}
