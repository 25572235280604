import React, {useState, useEffect, useContext} from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CalendarIcon from '@mui/icons-material/CalendarToday'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';

export default function TypeThree(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const MenuCardThree = {
    display:'flex',
    alignItems:'center',
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background: theme.palette.background.alternate,
    borderRadius:4,
    border:`1px solid ${theme.palette.background.borderColor}`,
    p:2.5,
    ml:{md:0, sm:2, xs:2},
    boxShadow: theme.shadows[0],
    transition:`0.5s`,
    minHeight: 120,
    "&:hover":{
      transition:`0.5s`,
      boxShadow: theme.shadows[0],
      background: theme.palette.background.alternate,
    },
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color: theme.palette.primary.contrastText,
      mb:1, 
      pl:5
    },
    '& h5':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:20,
      fontWeight:500,
      color:themeCss ? themeCss.theme_main_color:'',
      pl:5,
      
    },
    '& h6':{
        fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
        fontSize:16,
        fontWeight:100,
        color: theme.palette.primary.contrastText,
        pl:5,
      }
}


const IconStyle = {
    fontFamily: themeCss ? themeCss.theme_body_font.replace("+"," ") : '',
    borderRadius:0,
    background: themeCss ? themeCss.theme_main_color:'',
    color:themeCss ? themeCss.theme_primary_font_color:`#000 !important`,
    textDecoration:`none`,
    fontSize: 14,
    fontWeight:500,
    position:'absolute',
    ml:-5,
    width:75,
    height:75,
    borderRadius:1,
    justifyContent:'center',
    alignItems:'center',
    display:'flex',
    border:`3px solid ${themeCss ? themeCss.theme_main_color:''}`,
    boxShadow:theme.shadows[1]
};

  return (
    <Card sx={MenuCardThree}>
        <CardMedia sx={IconStyle}>
            <CalendarIcon />
        </CardMedia>
        <CardContent sx={{py:0,pb:`0px !important`, width:'100%'}}>
            <Typography variant="h3">Happy Hours</Typography>
                <Typography component="div" sx={{width:'100%'}}>
                    <Typography variant="h6">
                    {props.data && props.data.happy_hours}
                    </Typography>
                </Typography>
        </CardContent>
    </Card>
  );
}
