import React, {Fragment, useState, useEffect, useContext, useRef  } from "react";
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  InputLabel,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
// import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import DateIcon from "@mui/icons-material/CalendarToday";
import TimeIcon from "@mui/icons-material/AccessTime";
import ButtonTypeOne from "../../buttons/TypeOne";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CreateIcon from "@mui/icons-material/Create";
import KeyIcon from "@mui/icons-material/Key";
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from "moment";
import { useAppContext } from '../../../AppContext';
import { useTheme } from '@mui/material/styles';


import { createTheme, ThemeProvider } from '@mui/material/styles';
import { nanoid } from "nanoid";

export default function TRDesign1({trData}) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState();

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    let custom_dates = siteData ? JSON.parse(siteData.club_inner[0].onelink_data) : null;
    setThemeCss(custom_css);
    setTableData(custom_dates);
    setLoading(false)
  }, [themeData]);
  


  const {
    date,
    setDate,
    timeOriginal,
    setTimeOriginal,
    // time,
    // setTime,
    noOfPersons,
    setNoOfPersons,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    comments,
    setComments,
    error,
    setError,
    otp,
    setOtp,
    success,
    setSuccess,
    sendOtp,
    verifyOtp,
    submitting,
  } = trData;

  const [showResendOtp, setshowResendOtp] = useState(false);
  const [timer, settimer] = useState(61);

  const resendOtp = () => sendOtp();

  useEffect(() => {
    step === 3 &&
      (timer > 0
        ? setTimeout(() => settimer(timer - 1), 1000)
        : setshowResendOtp(true));
  }, [timer]);

  const formContainerOne = {
    width: "75%",
    background: "rgba(0, 0, 0, 0.77)",
    border: `1px solid #505050`,
    borderRadius: 2,
    textAlign: "center",
    p: 3,
    "& h2": {
      color: theme.palette.primary.title,
      fontFamily: themeCss
        ? themeCss.theme_primary_font.replace("+", " ")
        : "",
      fontSize: 28,
      fontWeight: 500,
      textTransform: "capitalize",
      mb: 2,
      letterSpacing: 1,
      pb:2
    },
  };

  const iconStyle = {
    fontSize: 14,
    color: `#7B7B7B !important`,
    width: 25,
    height: 25,
    p: 0.5,
    alignSelf: "center",
  };

  const InputStyle = {
    border: "1px solid #5D5D5D",
    background: "#343434",
    fontSize: `16px !important`,
    fontWeight:700,
    outline: "none",
    "& focus": {
      outline: "none",
    },
    fontFamily: themeCss
      ? themeCss.theme_body_font.replace("+", " ")
      : "",
  };

  const controlLabel = {
    fontWeight: 700,
    fontSize: 14,
    textAlign: "left",
    m: "0 15px 0 0 ",
    color: "#2A2A2A",
    fontFamily: themeCss
      ? themeCss.theme_body_font.replace("+", " ")
      : "",
    alignSelf: "baseline",
    display: "flex",
    flexDirection: "row",
    mb: 2,
  }; 
  
  const BtnItem = {
    borderRadius: 0,
    padding: 0.5,
    m:0.3,
    background: '#efefef', 
    color:"#000",       
    minWidth: 40,
    height: 40,  
    "&:hover": {
      backgroundColor: themeCss ? themeCss.theme_main_color : '',  
      color: themeCss ? themeCss.theme_primary_font_color : '#000',
    },
    "& h5": {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "",
    },
    "& h6": {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "",
    },
  };
  const BtnItemActive = {
    backgroundColor: themeCss ? themeCss.theme_main_color : '',  
    color: themeCss ? themeCss.theme_primary_font_color : '#000',
    m:0.3,
    p: 0.5,
    minWidth: 40,
    height: 40,
    borderRadius: 0,
     "&:hover": {
      backgroundColor: themeCss ? themeCss.theme_main_color : '',  
      color: themeCss ? themeCss.theme_primary_font_color : '#000',
    },
    "& h5": {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "",
      color: themeCss ? themeCss.theme_primary_font_color : `#151414`,
    },
    "& h6": {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "",
      color: themeCss ? themeCss.theme_primary_font_color : `#151414`,
    }
  };

  const [showCommentsBox, setShowCommentsBox] = useState(false);
  const [timePickerModal, setTimePickerModal] = useState(false);
  const [step, setStep] = useState(1);

  const changeStep = (e, value) => {
    e.preventDefault();

    if (value == 1) {
      setError({
        msg: "",
        state: false,
      });
      setStep(value);
    }

    if (value == 2) {
      if (!time) {
        setError({
          msg: "Time is Invalid",
          state: true,
        });
      }else if (noOfPersons < 1) {
        setError({
          msg: "No Of Persons is Invalid",
          state: true,
        });
      } else {
        setError({
          msg: "",
          state: false,
        });
        setStep(value);
      }
    }

    if (value == 3) {
      const today = new Date().toString().slice(4, 15);
      const now = new Date().toString().slice(16, 21);

      if (name === "") {
        setError({
          msg: "Enter a Valid Name",
          state: true,
        });
      } else if (phone.length < 5) {
        setError({
          msg: "Enter a Valid Phone",
          state: true,
        });
      } else if (today === date) {
        if (time < now) {
          setError({
            msg: "Select a Valid Time",
            state: true,
          });
        } else {
          setError({
            msg: "",
            state: false,
          });
          setStep(value);
          sendOtp();
          settimer(60);
        }
      } else {
        setError({
          msg: "",
          state: false,
        });
        setStep(value);
        sendOtp();
        settimer(60);
      }
    }

    if (value == 4) {
      if (otp.length !== 4) {
        setError({
          msg: "Enter a valid OTP",
          state: true,
        });
      } else {
        setError({
          msg: "",
          state: false,
        });
        verifyOtp();
      }
    }
  };

  const toggleCommentsBox = (e) => {
    e.preventDefault();
    setShowCommentsBox(true);
  };

  const theme1 = createTheme({
    components: {
    MuiPickersDay:{
      styleOverrides:{
        root: {
          borderRadius: 0,
          padding: 12,
          background: '#efefef',          
          ":hover": {
            backgroundColor: themeCss ? themeCss.theme_main_color : '',  
            color: themeCss ? themeCss.theme_primary_font_color : '#000',
          },          
          "&.Mui-selected":{
            backgroundColor: themeCss ? themeCss.theme_main_color: '',  
            color: themeCss ? themeCss.theme_primary_font_color : '#000',          
            ":hover": {
              backgroundColor: themeCss ? themeCss.theme_main_color : '',  
              color: themeCss ? themeCss.theme_primary_font_color : '#000',
            },
            ":focus": {
              backgroundColor: themeCss ? themeCss.theme_main_color : '', 
              color: themeCss ? themeCss.theme_primary_font_color : '#000',
            },
          }
        }
      }
    },         
    MuiClockNumber:{
      styleOverrides:{
        root: {       
          ":hover": {
            backgroundColor: themeCss ? themeCss.theme_main_color : '',  
            color: themeCss ? themeCss.theme_primary_font_color : '#000',
          },          
          "&.Mui-selected":{
            backgroundColor: themeCss ? themeCss.theme_main_color: '',  
            color: themeCss ? themeCss.theme_primary_font_color : '#000',          
            ":hover": {
              backgroundColor: themeCss ? themeCss.theme_main_color : '',  
              color: themeCss ? themeCss.theme_primary_font_color : '#000',
            },
            ":focus": {
              backgroundColor: themeCss ? themeCss.theme_main_color : '', 
              color: themeCss ? themeCss.theme_primary_font_color : '#000',
            },
          }
        }
      }
    }, 
    MuiClockPointer:{
      styleOverrides:{
        root: {         
          backgroundColor: themeCss ? themeCss.theme_main_color: '',
          ":hover": {
            backgroundColor: themeCss ? themeCss.theme_main_color : '',  
            color: themeCss ? themeCss.theme_primary_font_color : '#000',
          },          
        }
      }
      },
    MuiClock:{
      styleOverrides:{
        pin: {                    
          backgroundColor: themeCss ? themeCss.theme_main_color: '',                    
        }
      }
    },
    MuiButton:{
      styleOverrides:{
        root: {                    
          color: themeCss ? themeCss.theme_main_color: '',                    
        }
      }
    },
  },
});

  const getTimeSlots = (start, end) => {  
    // alert(start+'---'+end)
    let hours = parseInt(start);
    let mins = parseInt(start);
    const mins2 = Math.round(mins / 30) * 30;
    const startNew = `${hours}:${mins2}`;

    let hours2 = parseInt(end);
    let mins21 = parseInt(end);
    const mins22 = Math.round(mins21 / 30) * 30;
    const endNew = `${hours2}:${mins22}`;

    var startTime = moment(startNew, "HH:mm");
    var endTime = moment(endNew, "HH:mm");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }

    var timeStops = [];
    const current_date = new Date();
    const current_time = `${new Date().getHours()}:${new Date().getMinutes()}`;

    while (startTime <= endTime) {
      timeStops.push(new moment(startTime).format("HH:mm"));
      startTime.add(30, "minutes");
    }

    const final = timeStops.filter((e) => {
      if (current_date == date) {
        return e >= current_time;
      } else {
        return e;
      }
    });

    final.pop();    

    if (moment(current_date).format('YYYY-mm-dd') == moment(date).format('YYYY-mm-dd')) {
      if (end >= current_time) {
        final.push(end);
      }
    } else {
      final.push(end);
    }
    return final;
  };

  const [times, setTimes] = useState([])
  const [time, setTime] = useState(times ? times[0] : null);  

  useEffect(() => {
    let day = moment(date).format('ddd');
    let start_time = '';
    let end_time = '';  
    if (tableData && tableData.tableReservationsTimings) {
      for (let i = 0; i < 7; i++) {
        if (tableData.tableReservationsTimings[i].day == day) {
          start_time = tableData.tableReservationsTimings[i].start_time
          end_time = tableData.tableReservationsTimings[i].end_time
        }
      }
    } 
    setTimes(getTimeSlots(start_time, end_time));
  }, [date, times])
  
  useEffect(() => {
    setError(error);
  }, [date, time, error]);

  const showTimePickerModal = () => {
    setTimePickerModal(true)
  }
   const closeTimePickerModal = () => {
    setTimePickerModal(false)
  }

  const setDate2 = (e) => {
    setDate(e);
    setTime('')
  }

  const handleChangeTimings = (event, e) =>  {
    event.preventDefault();
    setTime(e);
    setTimeOriginal(e);
    console.log('Clicked:', e);
    console.log('time:', time);
    console.log('timeOriginal:', timeOriginal);
    setTimePickerModal(false)
  }

  const stepOne = () => {
    return (
      <Box
        component="form"
        sx={{
          // ...formContainerOne,
          display: "flex",
          alignItems: "center",
          flexDirection:{md:'row', sm:'column', xs:'column'},
          "& .MuiTextField-root": {
            margin:{md:"0 15px 0 0",sm:"10px", xs:"10px"},
            width: {md:"33%",sm:"100%",xs:"100%"},
            outline: "none",
            "& .MuiOutlinedInput-root": {
              border: "none",
              borderRadius: 0,
              outline: "none",
              fontSize: 14,
              py: 0.4,
              px: 1,
              color: "#fff",
              fontWeight:500,
              fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
              '& .MuiOutlinedInput-notchedOutline':{
                borderColor: themeCss ? themeCss.theme_primary_font_color: ''
              }
            },
          },
        }}
        noValidate
        autoComplete="off"
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme1}>
          <MobileDatePicker
            disablePast
            value={date}
            onChange={setDate2}            
            format={"DD MMM"}
            sx={{
              ...InputStyle,
              "& input ": {
                p: `10px !important`,
              },
              "& .MuiIconButton-root":{
                fontSize: 14,
                color: `#7B7B7B !important`,
                alignSelf: "center",
              }
            }}              
            />
            
            <TextField
              label=""
              type="text"
              id="time"
              variant="outlined"
              placeholder="00:00"
              size="small"
              value={time}
              onClick={showTimePickerModal}
              onKeyDown={showTimePickerModal}
              sx={{
                ...InputStyle,
                "& input ": {
                  p: `10px !important`,
                },
              }}
            />
                
            {/* <TextField
              type="text"
              id="time"            
              variant="outlined"
              placeholder="00:00"
              size="small"
              value={time}
              onClick={showTimePickerModal}
              onKeyDown={showTimePickerModal}
              sx={{
                ...InputStyle,
                "& input ": {
                  p: `10px !important`,
                },
              }}
            />            */}
          </ThemeProvider>
        </LocalizationProvider>
        <TextField
          type="number"
          id="noOfPersons"          
          variant="outlined"
          placeholder="No of Persons"
          size="small"
          value={noOfPersons ? noOfPersons : 1}
          onChange={(e) => setNoOfPersons(e.target.value >= 1 && e.target.value <= 20 ? e.target.value : noOfPersons)}
          sx={{
            ...InputStyle,
            "& input ": {
              p: `10px !important`,
            },
          }}
        />
        <Box onClick={(e) => changeStep(e, 2)} sx={{mt:{md:0, sm:2, xs:2}}}>
          <ButtonTypeOne text="Next" link="" sx={{height:'50px', border:'none', width:'100%'}}  />
        </Box>
        <Dialog
          open={timePickerModal}
          onClose={closeTimePickerModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='xs'
          sx={{
            '& .MuiPaper-root':{
                background:'#fff !important',
                '& h2':{
                  color: `${theme.palette.background.default} !important`,
                  fontFamily: themeCss ? themeCss.theme_primary_font.replace("+", " "): "",
                }
            }
          }}
          // fullWidth={true}
        >
          <DialogTitle sx={{
            fontSize: 12,
            fontWeight: 100,
            color: `rgba(0, 0, 0, 0.6)`,
            textTransform: 'uppercase',
            letterSpacing: 1,
            px:2
          }}>Select Time</DialogTitle>
          <DialogContent variant="div" sx={{px:2}}>
            <Typography variant="h2" sx={{fontSize:`2.215rem`, fontWeight:400,  mb:4}}>{moment(date).format('ddd MMM, DD')}</Typography>
            <>
             {times.map(
                (e, i) =>
                  e !== null && (                          
                      <Button type="button"
                        style={{width:40, height:40, display:'inline-flex', cursor:'pointer', textAlign:'center', alignItems:'center'}}
                        sx={time == e || (i == 0 && !time) ? BtnItemActive : BtnItem}
                        value={time}
                        onClick={(event) => handleChangeTimings(event,e)}
                        key={nanoid(30)}
                      >
                        <Typography variant="h6">{e}</Typography>
                      </Button>                          
                    )
                )}            
            </>
          </DialogContent>
          <DialogActions>            
            <Button onClick={closeTimePickerModal} autoFocus sx={{ color: themeCss ? themeCss.theme_main_color : ''}}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const stepTwo = () => {
    return (
      <Typography componnet="div">
        <Box
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection:{md:'row', sm:'column', xs:'column'},
            "& .MuiTextField-root": {
              margin: {md:"0 15px 0 0", sm:"10px 0",xs:"10px 0"},
              width: {md:"33%",sm:'100% !important',xs:'100% !important'},
              outline: "none",
              "& .MuiOutlinedInput-root": {
                border: "none",
                borderRadius: 0,
                outline: "none",
                fontSize: 14,
                py: 0.4,
                px: 1,
                color: "#fff",
                width:'100%',
                fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
                '& .MuiOutlinedInput-notchedOutline':{
                  borderColor: themeCss ? themeCss.theme_primary_font_color: ''
                }
              },
            },
          }}
          noValidate
          autoComplete="off"
        >
          <Stack flexDirection={{md:'row', sm:'column', xs:'column'}} sx={{width:'100%'}}>
            <TextField
              id="name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon sx={iconStyle} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder="Name"
              // sx={InputStyle}
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{
                ...InputStyle,
                "& input ": {
                  p: `10px !important`,
                },
              }}
            />
            <TextField
              id="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon sx={iconStyle} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder="Email"
              // sx={InputStyle}
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                ...InputStyle,
                "& input ": {
                  p: `10px !important`,
                },
              }}
            />
            {/* <Box className="mobile_field" sx={{ ...reactInputNew, mr: {md:0, sm:0, xs:0}, margin:{md:"0",sm:"10px 0",xs:"10px 0"}}}>
              <PhoneInput
                international={true}
                withCountryCallingCode={true}
                limitMaxLength={true}
                countryCallingCodeEditable={false}
                defaultCountry="IN"
                smartCaret={true}
                placeholder="Mobile"
                value={phone}
                onChange={setPhone}
              /> 
              </Box> */}
              <TextField
                id="phone"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneAndroidIcon sx={iconStyle} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                placeholder="Mobile"
                maxLength="10"
                size="small"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                sx={{
                  ...InputStyle,
                  "& input ": {
                    p: `10px !important`,
                  },
                }}
              />
           
            <Typography component="div" className="mobileMode" sx={{display:'flex', alignItems:'center'}}>
            {!showCommentsBox ? (
              <InputLabel
                sx={{
                  ...controlLabel,
                  mt: 1,
                  cursor: "pointer",
                  color: "grey",
                }}
                onClick={toggleCommentsBox}
              >
                <AddIcon
                  sx={{
                    fontSize: 13,
                    color: themeCss ? themeCss.theme_main_color : `#151414`,
                  }}
                />
                &nbsp;Special Instructions
              </InputLabel>
            ):(
              <TextField
                id="comments"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreateIcon sx={iconStyle} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                placeholder="Special Instructions"
                size="small"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                sx={{
                  ...InputStyle,
                  "& input ": {
                    p: `10px !important`,
                    fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
                    '& .MuiOutlinedInput-notchedOutline':{
                      borderColor: themeCss ? themeCss.theme_primary_font_color: '',
                    }
                  },
                }}
                className="mobileMode"
              />
            )}
            </Typography>
          </Stack>
          <Typography component="div" className="mobileMode" sx={{width:'100%'}}>
            <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{width:'100%'}}>
              <Typography component="div">
                <InputLabel
                  sx={{ ...controlLabel, mt: 1, mb:0, cursor: "pointer", color: "grey" }}
                  onClick={(e) => changeStep(e, 1)}
                >
                  <KeyboardBackspaceIcon
                    sx={{
                      fontSize: 13,
                      color: themeCss ? themeCss.theme_primary_font_color : `#151414`,
                    }}
                  />
                  &nbsp;Back
                </InputLabel>
              </Typography>
              <Box onClick={(e) => changeStep(e, 3)} sx={{mt:{md:0, sm:2, xs:2}}}>
                <ButtonTypeOne text="Next" link="" sx={{height:'50px', border:'none'}}  />
              </Box>
            </Stack>
          </Typography>
          <Typography component="div" className="desktopMode">
            <Box onClick={(e) => changeStep(e, 3)} sx={{mt:{md:0, sm:2, xs:2}}}>
              <ButtonTypeOne text="Next" link="" sx={{height:'50px', border:'none'}} />
            </Box>
          </Typography>
          
         
        </Box>
        <Typography component="div" className="desktopMode">
          <Stack
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection:{md:'row', sm:'column', xs:'column'},
              mt: 2,            
            }}
          >
          <Typography component="div" sx={{display:'flex',alignItems:'center', width:'100%',
        
            "& .MuiOutlinedInput-root":{
              fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
              '& .MuiOutlinedInput-notchedOutline':{
                borderColor: '#c4c4c4'
              }
            }
        }}>
            {!showCommentsBox ? (
              <InputLabel
                sx={{
                  ...controlLabel,
                  mt: 1,
                  cursor: "pointer",
                  color: "grey",
                }}
                onClick={toggleCommentsBox}
              >
                <AddIcon
                  sx={{
                    fontSize: 13,
                    color: themeCss ? themeCss.theme_main_color : `#151414`,
                  }}
                />
                &nbsp;Special Instructions
              </InputLabel>
            ):(
              <TextField
                id="comments"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreateIcon sx={iconStyle} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                placeholder="Special Instructions"
                size="small"
                value={comments}
                multiline
                maxRows={2}
                onChange={(e) => setComments(e.target.value)}
                sx={{
                  ...InputStyle,
                  width:{md:'62%', sm:'100%', xs:'100%'},
                  color: `#5D5D5D !important`,
                  "& input ": {
                    p: `10px !important`,
                    fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
                  },
                  "& .MuiOutlinedInput-root":{
                    color: `#fff !important`,
                    borderRadius:0,
                    height:'50px',
                    '& .MuiOutlinedInput-notchedOutline':{
                      borderColor: themeCss ? themeCss.theme_primary_font_color: ''
                    }
                  }
                }}
              />
            )}
          </Typography>
          <Typography component="div" className="DesktopMode">
            <InputLabel
              sx={{ ...controlLabel, mt: 1, cursor: "pointer", color: "grey", alignItems:'center' }}
              onClick={(e) => changeStep(e, 1)}
            >
              <KeyboardBackspaceIcon
                sx={{
                  fontSize: 13,
                  color: themeCss ? themeCss.theme_primary_font_color : `#151414`,
                }}
              />
              &nbsp;Back
            </InputLabel>
          </Typography>
          </Stack>
        </Typography>
      </Typography>
    );
  };

  const stepThree = () => {
    return (
      <Typography
        component="div"
        sx={{
          // ...formContainerOne,
          width:'100%',
        }}
      >
        <Box
          component="form"
          sx={{
            // ...formContainerOne,
            display: "flex",
            alignItems: "center",
            flexDirection:{md:'row', sm:'column', xs:'column'},
            "& .MuiTextField-root": {
              margin: {md:"0 15px 0 0", sm:"10px 0",xs:"10px 0"},
              width: {md:"33%",sm:'100% !important',xs:'100% !important'},
              outline: "none",
              "& .MuiOutlinedInput-root": {
                border: "none",
                borderRadius: 0,
                outline: "none",
                fontSize: 14,
                py: 0.4,
                px: 1,
                color: "#fff",
                fontWeight:500,
                fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "",
                '& .MuiOutlinedInput-notchedOutline':{
                  borderColor: themeCss ? themeCss.theme_primary_font_color: ''
                }
              },
            },
          }}
          noValidate
          autoComplete="off"
        >
          <Stack flexDirection={{md:'row', sm:'column', xs:'column'}} sx={{width:'100%'}}>
            <TextField
              type="number"
              id="otp"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon sx={iconStyle} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder="OTP"
              // sx={InputStyle}
              size="small"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              sx={{
                ...InputStyle,
                "& input ": {
                  p: `10px !important`,
                },
              }}
            />
          </Stack>
          <Box onClick={(e) => changeStep(e, 4)} sx={{mt:{md:0, sm:2, xs:2}}}>
            <ButtonTypeOne
              text={submitting ? "Submitting" : "Submit"}
              link=""
            />
          </Box>
        </Box>
        <Typography
          component="div"
          sx={{
            display: "flex",
            justifyContent: { md:"space-between", sm:'space-between', xs:'space-between'},
            flexDirection:'row',
            alignItems:{md:'center', sm:'center', xs:'center'},
            mt: 2,
            width:'100%'
          }}
        >
          <InputLabel
            sx={{ ...controlLabel, mt: 1, cursor: "pointer", color: "grey", display:'flex', alignItems:'center' }}
            onClick={(e) => changeStep(e, 2)}
          >
            <KeyboardBackspaceIcon
              sx={{
                fontSize: 13,
                color: themeCss ? themeCss.theme_primary_font_color : `#151414`,
              }}
            />
            &nbsp;Back
          </InputLabel>
          {showResendOtp ? (
            <Typography
              variant="text"
              sx={{ color: "#fff", fontSize: "10px", cursor: "pointer" }}
              onClick={() => resendOtp()}
            >
              Resend
            </Typography>
          ) : (
            <Typography
              variant="text"
              sx={{
                fontFamily: "DM Sans",
                color: "#fff",
                fontSize: "10px",
              }}
            >
              {timer}
            </Typography>
          )}
        </Typography>
      </Typography>
    );
  };

  return (
    <Stack
      diaplay="flex"
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ 
        minHeight: "80vh", 
        width:{md:(step == 3 ? '50%': '100%'),
        sm:'100%', xs:'100%'}, 
        margin:'0 auto',
    }}
    >
      <Typography component="div" sx={formContainerOne}>
        {success ? (
          <Typography
            variant="p"
            sx={{ my: 3, display: "block", fontSize: "13px", color: "green" }}
          >
            Thank you for submitting. We will get back to you with the
            confirmation soon.
          </Typography>
        ) : (
          <>
            {step === 1 && (
              <Typography variant="h2">Reserve a table</Typography>
            )}
             
            {step === 2 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                  flexDirection:{md:'row', sm:'column', xs:'column'}
                }}
              >
                <Typography variant="h2" sx={{mb:`8px !important`}}>Reserve a table</Typography>
                  <Typography variant="h6" sx={{fontSize: "16px", textAlign:'left', fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "", }}>                    
                  {moment(date).format('ddd DD')} | {time} | {noOfPersons} Person(s)
                </Typography>
              </Box>
            )}
              
            {step === 3 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection:{md:'row', sm:'column', xs:'column', width:{md:'80%',sm:'100%', xs:'100%'}, margin:'0 auto'}
                }}
              >
                <Typography variant="h2" sx={{mb:`8px !important`}}>Reserve a table</Typography>
                <Typography variant="h6" sx={{ fontSize: "16px",fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " ") : "", }}>
                  {moment(date).format('ddd DD')} | {time} | {noOfPersons} Person(s)
                </Typography>
              </Box>
            )}
          </>
        )}

        {
          !success && (
            <>
              {step === 1 && stepOne()}
              {step === 2 && stepTwo()}
              {step === 3 && stepThree()}
            </>
          )
        }
          
        {error.state && (
          <Typography
            variant="p"
            sx={{ mt: 2, display: "block", fontSize: "13px", color: "red" }}
          >
            {error.msg}
          </Typography>
        )}
      </Typography>
    </Stack>
  );
}
