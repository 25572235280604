import React, {useState, useEffect, useContext} from 'react';
import { Container, Typography, Grid, Stack } from '@mui/material';
import DealOne from './deals/DealOne';
import DealTwo from './deals/DealTwo';
import DealThree from './deals/DealThree';
import DealFour from './deals/DealFour';
import DealFive from './deals/DealFive';
import DealSix from './deals/DealSix';
import { nanoid } from 'nanoid';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Link from 'next/link';
import { useAppContext } from '../AppContext';
import { useTheme } from '@mui/material/styles';

const renderDealCard = (type, deal) => {
    switch(type){
      case 1: return <DealOne data={deal}/>;
      case 2: return <DealTwo data={deal}/>;
      case 3: return <DealThree data={deal}/>;
      case 4: return <DealFour data={deal}/>;
      case 5: return <DealFive data={deal}/>;
      case 6: return <DealSix data={deal}/>;
      default: return <DealOne data={deal}/>;
    };
  };

export default function HomeDeals(props) {

  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);
    
    
    const HomeDealsContainer = {
        pt:{md:10, sm:7, xs:5},
        '& h2':{
            fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
            fontSize:35,
            fontWeight:700,
            color: theme.palette.primary.title
        },
        '& a':{
            fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
            fontSize:14,
            fontWeight:400,
            color: themeCss ? themeCss.theme_primary_font_color : "",
            "&:hover":{
                color: themeCss ? themeCss.theme_main_color : "",
            }
        }
    };

  return (
    <>
        {props && props.data && props.data.length ? (
            <Container maxWidth="lg" sx={HomeDealsContainer}>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center" sx={{pb:{md:5, sm:3, xs:3}}}>
                    <Typography variant="h2">Deals</Typography>
                    <Link href={`/deals`} >View All</Link>
                </Stack>
                <Typography component="div" className="desktopMode">
                    <Grid container spacing={{md:8, sm:5, xs:2}}>
                        {siteData && props.data.slice(0, 3).map((deal, index) => (
                            <>
                                {index <= 5 ? (
                                    <>
                                        {
                                        siteData && siteData.sections[0].deals_type == 1 || 
                                        siteData && siteData.sections[0].deals_type == 4 ? (
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={nanoid(30)}>
                                                {renderDealCard(siteData && siteData.sections[0].deals_type, deal)}
                                            </Grid>
                                        ):(
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={nanoid(30)}>
                                                {renderDealCard(siteData && siteData.sections[0].deals_type, deal)}
                                            </Grid>
                                        )}
                                    </>
                                 ):('')}
                            </>
                        ))}
                    </Grid>
                </Typography>
                <Typography component="div" className="mobileMode">
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={20}                                                   
                    navigation                        
                    autoplay={true}
                    loop={true}
                    slidesPerView={siteData && siteData.sections[0].events_type == 6 ? 1: 1.15}
                    breakpoints={{
                      768: {
                          width: 768,
                          slidesPerView: 2,
                      },
                      425: {
                          width: 425,
                          slidesPerView: 1.25,
                      },
                    }}
                >
                        {props.data && props.data.slice(0, 3).map((deal) => (
                            <>
                            {
                            siteData && siteData.sections[0].deals_type == 1 || 
                            siteData && siteData.sections[0].deals_type == 4 ? (
                                <SwiperSlide key={nanoid(30)}>
                                    {renderDealCard(siteData && siteData.sections[0].deals_type, deal)}
                                </SwiperSlide>
                            ):(
                                <SwiperSlide key={nanoid(30)}>
                                    {renderDealCard(siteData && siteData.sections[0].deals_type, deal)}
                                </SwiperSlide>
                            )}
                            </>
                        ))}
                    </Swiper>
                </Typography>
            </Container>
        ):("")}
    </>
  );
}
