import React, {Fragment, useState, useEffect, useContext} from 'react';
import { Typography, Stack, Drawer, Grid, Box } from '@mui/material';
import Container from '@mui/material/Container';
import Image from 'next/image';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { nanoid } from 'nanoid';
import PageLoader from '@/components/loader'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';


var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '10px',
  // variableWidth: true,
  infinite: true,
  focusOnSelect: true,
  cssEase: 'linear',
  touchMove: true,
  arrows:false
};


function NavbarThree(props) {
  const [isOpen, setOpen] = useState(false)
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const bannerContainer = {    
    // background: `url(${themeData ? themeData.home_banner : ''})`,
    height:'100vh',
    width:'100%',
    backgroundPosition:'center',
    backgroundSize:'cover',
    py:2,
    '& img':{
      width:'100%',
      height:'100vh'
    }
  }
  
  return (
    <Fragment>
       {loading ? ( <PageLoader /> ) : (
          <>
            {themeData && themeData.home_banner_type == 1 ? (
              <Typography component="div"
                sx={{
                  ...bannerContainer,
                  background: `url(${themeData && themeData.home_banner})`,
                }}
              >
                <Slider {...settings} className="NavBarThreeSlider">
                  {siteData && siteData.restaurent_images.map((item) => (
                    <div className="item" key={nanoid(30)}>
                      <Image src={item.img_path} width="100" height="100" alt="..." />
                    </div>
                  ))}
                </Slider>
              </Typography>
            ) : (
              <Typography component="div" className="myvideo" sx={{ ...bannerContainer, pb: 0 }}>
                <video src={themeData && themeData.home_banner_video} autoPlay loop muted playsInline={true} disablePictureInPicture={true} />
                <Typography component="div" className="content">
                  <Container maxWidth="lg">
                    <Stack
                      diaplay="flex"
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      <Link href={"/"}>
                        <Image
                          src={siteData && siteData ? siteData.club_inner[0].logo_path : ""}
                          alt="..."
                          width={100}
                          height={55}
                          sizes="(max-width: 325px) 100vw"
                          style={{ objectFit: "contain" }}
                        />
                      </Link>
                      <Typography component="div" className="desktopMode">
                      {renderNavMenu(siteData && siteData.sections[0].navbar_menu_type)}
                      </Typography>
                      <Typography component="div" className="mobileMode">
                        {renderNavMenu(1)}
                      </Typography>
                    </Stack>                    
                  </Container>
                </Typography>
              </Typography>
            )}
          </>
        )}
        
    </Fragment>
    
  )
}

export default NavbarThree