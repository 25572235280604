import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Typography } from '@mui/material';
export default function GalleryThree(props) {
    
  return (
      <Typography component="div">
        <ImageList cols={3} gap={16} variant="woven">
        {props.data && props.data.map((item) => (
            <ImageListItem key={item.img_path} sx={{
              'img':{
                height:'250px'
              }
            }}>
            <img
                src={`${item.img_path}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${item.img_path}?w=164&h=164&fit=crop&auto=format&dpr=2 1x`}
                alt={item.id}
                loading="lazy"
                style={{borderRadius:20}}
            />
            </ImageListItem>
        ))}
        </ImageList>
      </Typography>
  );
}
