import React, { Fragment, useState, useEffect } from "react";
import { Typography, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import Link from "next/link";
import Image from "next/image";
import NavMenuOne from "./menus/NavMenuOne";
import NavMenuTwo from "./menus/NavMenuTwo";
import TRDesign2 from "./tableReservations/TRDesign2";
import PageLoader from '@/components/loader'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';

const renderNavMenu = (type, menu, trData) => {
  switch (type) {
    case 1:
      return <NavMenuOne />;
    case 2:
      return <NavMenuTwo />;
    default:
      return <NavMenuTwo />;
  }
};

function NavbarOne({ trData }) {  
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css)
    setLoading(false)
  }, [themeData]);
  

  const bannerContainer = {
    // background: `url(${
    //   siteData ? siteData.sections[0].home_banner : ""
    // })`,
    height: "100vh",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    pb: 2,
    "& h2": {
      fontFamily: themeCss
        ? themeCss.theme_primary_font.replace("+", " ")
        : "Poppins",
      fontSize: 28,
      fontWeight: 500,
      textTransform: "capitalize",
      mb: 2,
      letterSpacing: 1,
    },
    '& img':{
      height:'100%'
    }
  };

  return (
    <Fragment>
      {loading ? ( <PageLoader /> ) : (
        <>
        {themeData && themeData.home_banner_type == 1 ? (
          <Typography
            component="div"
            sx={{
              ...bannerContainer,            
              background: `url(${
                siteData && siteData ? siteData.sections[0].home_banner : ""
              })`,
            }}
          >
            <Container maxWidth="lg">
              <Stack
                diaplay="flex"
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Link href={"/"}>
                  <Image
                    src={siteData && siteData ? siteData.club_inner[0].logo_path : ""}
                    alt="..."
                    width={100}
                    height={55}
                    sizes="(max-width: 325px) 100vw"
                    style={{objectFit: "contain"}}	
                  />
                </Link>
                <Typography component="div" className="desktopMode">
                  {renderNavMenu(siteData && siteData.sections[0].navbar_menu_type)}
                </Typography>
                <Typography component="div" className="mobileMode">
                  {renderNavMenu(1)}
                </Typography>
              </Stack>
            </Container>
            <TRDesign2 themeCss={themeCss} trData={trData}/>
          </Typography>
        ) : (
            <Typography component="div" className="myvideo" sx={{...bannerContainer, pb:0}}>
                <video src={themeData && themeData.home_banner_video} autoPlay loop muted playsInline={true} disablePictureInPicture={true} />
                <Typography component="div" className="content">
                  <Container maxWidth="lg">
                  <Stack
                    diaplay="flex"
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <Link href={"/"}>
                      <Image
                        src={siteData && siteData ? siteData.club_inner[0].logo_path : ""}
                        alt="..."
                        width={100}
                        height={55}
                        sizes="(max-width: 325px) 100vw"
                        style={{objectFit: "contain"}}	
                      />
                    </Link>
                    <Typography component="div" className="desktopMode">                  
                      {renderNavMenu(siteData && siteData.sections[0].navbar_menu_type)}
                    </Typography>
                    <Typography component="div" className="mobileMode">
                      {renderNavMenu(1)}
                    </Typography>
                  </Stack>
                    <TRDesign2  
                      trData={trData} 
                     />
                </Container>            
              </Typography>
            </Typography>
          )}
        </>
      )}
    </Fragment>
  );
}

export default NavbarOne;
