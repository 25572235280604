import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Typography } from '@mui/material';

export default function GalleryTwo(props) {

  return (
    <Typography component="div">
      <ImageList variant="masonry" cols={3} gap={16}>
        {props.data && props.data.map((item) => (
          <ImageListItem key={item.id}>
            <img
              src={`${item.img_path}?w=161&fit=crop&auto=format`}
              srcSet={`${item.img_path}?w=161&fit=crop&auto=format&dpr=2 2x`}
              alt={item.id}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Typography>
  );
}
