import React, {useState, useEffect} from 'react';
import { Container, Typography, Grid, Stack } from '@mui/material';
import MenuOne from './menus/MenuOne';
import MenuTwo from './menus/MenuTwo';
import MenuThree from './menus/MenuThree';
import MenuFour from './menus/MenuFour';
import MenuFive from './menus/MenuFive';
import MenuSix from './menus/MenuSix';
import { useAppContext } from '../AppContext';
import { useTheme } from '@mui/material/styles';

const renderMenuCard = (type, title, data1, data2) => {
  switch(type){
    case 1: return <MenuOne title={title} data1={data1} data2={data2} />;
    case 2: return <MenuTwo title={title} data1={data1} data2={data2} />;
    case 3: return <MenuThree title={title} data1={data1} data2={data2} />;
    case 4: return <MenuFour title={title} data1={data1} data2={data2} />;
    case 5: return <MenuFive title={title} data1={data1} data2={data2} />;
    case 6: return <MenuSix title={title} data1={data1} data2={data2} />;
    default: return <MenuOne title={title} data1={data1} data2={data2} />;
  };
};


export default function HomeMenus(props) {

  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);
    
    const HomeMenusContainer = {
     pt:{md:10, sm:7, xs:5},
     '& h2':{
        fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
        fontSize:35,
        fontWeight:700,
        marginBottom:3,
        color: theme.palette.primary.title
     },
    };

  return (
    <Container maxWidth="lg" sx={HomeMenusContainer}>
        <Typography variant="h2">Menus</Typography>
        <Typography component="div">
            <Grid container spacing={{md:5, sm:3, xs:2}}>

              {/* food menu */}
              {siteData && siteData.sections[0].menus_type == 1 && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  {renderMenuCard(siteData.sections[0].menus_type,'food menu', props.data1, props.data2)}
                </Grid>
              )}

              {siteData && (siteData.sections[0].menus_type == 2 || siteData.sections[0].menus_type == 3) && (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  {renderMenuCard(siteData.sections[0].menus_type,'food menu', props.data1, props.data2)}
                </Grid>
              )}

              {siteData && (siteData.sections[0].menus_type == 4 || siteData.sections[0].menus_type == 5 || siteData.sections[0].menus_type == 6) && (
                <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                  {renderMenuCard(siteData.sections[0].menus_type,'food menu', props.data1, props.data2)}
                </Grid>
              )}
                

                {/* drink menu */}

              {siteData && siteData.sections[0].menus_type == 1 && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  {renderMenuCard(siteData.sections[0].menus_type,'drink menu', props.data1, props.data2)}
                </Grid>
              )}

              {siteData && (siteData.sections[0].menus_type == 2 || siteData.sections[0].menus_type == 3) && (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  {renderMenuCard(siteData.sections[0].menus_type,'drink menu', props.data1, props.data2)}
                </Grid>
              )}

              {siteData && (siteData.sections[0].menus_type == 4 || siteData.sections[0].menus_type == 5 || siteData.sections[0].menus_type == 6) && (
                <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                  {renderMenuCard(siteData.sections[0].menus_type,'drink menu', props.data1, props.data2)}
                </Grid>
              )}
                
            </Grid>
      </Typography>
    </Container>
  );
}
