import React, {useState, useEffect, useContext} from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CallMadeIcon from '@mui/icons-material/CallMade';
import Link from 'next/link'
import MenuImage from '../../public/images/menu.png'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';

export default function MenuOne({title, data1, data2}) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const MenuCardOne = {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background: theme.palette.background.alternate,
    borderRadius:0,
    width:{md:300, sm:'100%', xs:'100%'},
    pl:4,
    height:60,
    mb:2,
    boxShadow: theme.shadows[0],
    transition:`0.5s`,
    "&:hover":{
      boxShadow: theme.shadows[1],
      transition:`0.5s`,
    },
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:22,
      fontWeight:500,
      color:theme.palette.primary.contrastText,
      textTransform:'uppercase'
    }
}


const ButtonStyle = {
    borderRadius:0,
    boxShadow: theme.shadows[0],
    background:themeCss ? themeCss.theme_main_color: ``,
    color: theme.palette.background.default,
    textDecoration:`none`,
    fontSize: 14,
    fontWeight:500,
    ml:4,
    height:60,
    '&:hover':{
      background:themeCss ? themeCss.theme_main_color: ``,
      color: theme.palette.background.default,
      textDecoration:`none`,
      opacity:0.9,
    }
};

  return (
     
    <Link href={`/menus`} >
      <Stack display="flex" flexDirection={"row"} sx={MenuCardOne}>
        <Typography variant="h3">{title}</Typography>
        <Button variant="contained" sx={{...ButtonStyle, width:50}} >
            <CallMadeIcon style={{fontSize:14}} />
        </Button>
      </Stack>     
    </Link>
  );
}
