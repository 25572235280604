import React, {Fragment, useState, useEffect, useContext} from 'react';
import { Typography, Stack, Drawer, Grid, Box } from '@mui/material';
import Container from '@mui/material/Container';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PinterestIcon from '@mui/icons-material/Pinterest';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Link from 'next/link';
import Image from 'next/image';
import { Navigation, Pagination, Autoplay, FreeMode, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import DefaultImage from '../../public/images/icons/Calendar.png'
import NavMenuOne from './menus/NavMenuOne';
import NavMenuTwo from './menus/NavMenuTwo';
import PageLoader from '@/components/loader';
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';

const renderNavMenu = (type, menu) => {
  switch(type){
    case 1: return <NavMenuOne data={menu}/>;
    case 2: return <NavMenuTwo data={menu}/>;
    default: return <NavMenuTwo data={menu}/>;
  };
};

function NavbarTwo() {

  const [swiper, updateSwiper] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(DefaultImage);
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css);
    setLoading(false)
  }, [themeData]);

  useEffect(() => {
    if (swiper && thumbsSwiper) {
      swiper.controller.control = thumbsSwiper;
      thumbsSwiper.controller.control = swiper;
    }
  }, [swiper, thumbsSwiper]);
  
  const bannerContainer = {
    height:'100vh',
    width:'100%',
    backgroundPosition:'center',
    backgroundSize:'cover',
    pb:2
  }
  
  const bannerTitle = {
    background: themeCss ? themeCss.theme_main_color : '',
    fontFamily: themeCss ? themeCss.theme_primary_font.replace("+"," ") : '',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: {md:60, sm:40, xs:35},
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: theme.palette.primary.title,
    mb:2,
    display:'inline-block',
    px:1
  }
  
  const socialStickyMenu = {
    position:'fixed',
    right:0,
    top:'35%',
    float:'right !important',
    zIndex:999,
    background: themeCss ? themeCss.theme_main_color: ``,
    padding:'10px',
    borderRadius:'10px 0 0 10px',
    '& a:not(:last-child)':{
      marginBottom: 1,
    },
    '& a':{
      color: theme.palette.background.default,
    }
  }  

  return (
    <Fragment>
       {loading ? ( <PageLoader /> ) : (
          <>
            {themeData && themeData.home_banner_type == 1 ? (
              <>
                <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  freeMode={false}
                  className='mySwiper2'
                >
                  {siteData && siteData.restaurent_images.map((item) => (
                    <>
                      {item.img_path ? (
                        <SwiperSlide key={item.id}>
                          <Typography component="div"
                            sx={{
                              ...bannerContainer,
                              background: `url(${item.img_path})`,
                            }}
                          >
                            <Container maxWidth="lg" >
                              <Stack diaplay="flex" flexDirection={"row"} justifyContent={'space-between'}>
                                <Link href={"/"}>
                                  <Image
                                    src={siteData && siteData ? siteData.club_inner[0].logo_path : ""}
                                    alt="..."
                                    width={100}
                                    height={55}
                                    sizes="(max-width: 325px) 100vw"
                                    style={{ objectFit: "contain" }}
                                  />
                                </Link>
                                <Typography component="div" className="desktopMode">
                                {renderNavMenu(siteData && siteData.sections[0].navbar_menu_type)}
                                </Typography>
                                <Typography component="div" className="mobileMode">
                                  {renderNavMenu(1)}
                                </Typography>
                              </Stack>
                              <Stack diaplay="flex" flexDirection={"column"} justifyContent={'center'} alignItems={'flex-start'} sx={{ minHeight: '75vh', width: '80%', m: '0 auto' }}>
                                <Typography variant="h1" sx={bannerTitle}>
                                  {themeData && themeData.banner_title}
                                </Typography>
                                {/* <Typography variant="h1" sx={bannerTitle}>never before...</Typography> */}
                              </Stack>
                            </Container>
                          </Typography>
                        </SwiperSlide>
                      ) : ('')}
                    </>
                  ))}
                </Swiper>
                {
                  siteData && (siteData.club_inner[0].social_facebook_link ||
                    siteData.club_inner[0].social_twitter_link ||
                    siteData.club_inner[0].social_instagram_link ||
                    siteData.club_inner[0].social_pintrest_link ||
                    siteData.club_inner[0].social_linkedin_link ||
                    siteData.club_inner[0].social_whatsapp_link ||
                    siteData.club_inner[0].social_youtube_link) ? (
                    <Stack diaplay="flex" flexDirection={"column"} justifyContent={'center'} alignItems={'center'} sx={socialStickyMenu}>
                      {siteData && siteData.club_inner[0].social_facebook_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_facebook_link} target="_blank"><FacebookRoundedIcon /></Link>
                      ) : ('')}
                      {siteData && siteData.club_inner[0].social_twitter_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_twitter_link} target="_blank"><TwitterIcon /></Link>
                      ) : ('')}
                      {siteData && siteData.club_inner[0].social_instagram_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_instagram_link} target="_blank"><InstagramIcon /></Link>
                      ) : ('')}                      
                      {siteData && siteData.club_inner[0].social_youtube_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_youtube_link} target="_blank"><YouTubeIcon /></Link>
                      ) : ('')}
                      {siteData && siteData.club_inner[0].social_linkedin_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_linkedin_link} target="_blank"><LinkedInIcon /></Link>
                      ) : ('')}
                      {siteData && siteData.club_inner[0].social_pintrest_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_pintrest_link} target="_blank"><PinterestIcon /></Link>
                      ) : ('')}
                      {siteData && siteData.club_inner[0].social_whatsapp_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_whatsapp_link} target="_blank"><WhatsAppIcon /></Link>
                      ) : ('')}
                    </Stack>
                  ) : ('')}                
              </>
            ) : (
              <Typography component="div" className="myvideo" sx={{...bannerContainer, pb:0}}>
                <video src={themeData && themeData.home_banner_video} autoPlay muted loop playsInline={true} disablePictureInPicture={true} />
                <Typography component="div" className="content">
                  <Container maxWidth="lg">
                  <Stack
                    diaplay="flex"
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <Link href={"/"}>
                      <Image
                        src={siteData && siteData ? siteData.club_inner[0].logo_path : ""}
                        alt="..."
                        width={200}
                        height={80}
                        sizes="(max-width: 325px) 100vw"
                        style={{objectFit: "contain"}}	
                      />
                    </Link>
                    <Typography component="div" className="desktopMode">                  
                    {renderNavMenu(siteData && siteData.sections[0].navbar_menu_type)}
                    </Typography>
                    <Typography component="div" className="mobileMode">
                      {renderNavMenu(1)}
                    </Typography>
                  </Stack>  
                  <Stack diaplay="flex" flexDirection={"column"} justifyContent={'center'} alignItems={'flex-start'} sx={{ minHeight: '75vh', width: '80%', m: '0 auto' }}>
                    <Typography variant="h1" sx={bannerTitle}>
                      {themeData && themeData.banner_title}
                    </Typography>
                    {/* <Typography variant="h1" sx={bannerTitle}>never before...</Typography> */}
                  </Stack>   
                  {
                  siteData && (siteData.club_inner[0].social_facebook_link ||
                    siteData.club_inner[0].social_twitter_link ||
                    siteData.club_inner[0].social_instagram_link ||
                    siteData.club_inner[0].social_linkedin_link ||
                    siteData.club_inner[0].social_pintrest_link ||
                    siteData.club_inner[0].social_whatsapp_link ||
                    siteData.club_inner[0].social_youtube_link) ? (
                    <Stack diaplay="flex" flexDirection={"column"} justifyContent={'center'} alignItems={'center'} sx={socialStickyMenu}>
                      {siteData && siteData.club_inner[0].social_facebook_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_facebook_link} target="_blank"><FacebookRoundedIcon /></Link>
                      ) : ('')}
                      {siteData && siteData.club_inner[0].social_twitter_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_twitter_link} target="_blank"><TwitterIcon /></Link>
                      ) : ('')}
                      {siteData && siteData.club_inner[0].social_instagram_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_instagram_link} target="_blank"><InstagramIcon /></Link>
                      ) : ('')}                      
                      {siteData && siteData.club_inner[0].social_youtube_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_youtube_link} target="_blank"><YouTubeIcon /></Link>
                      ) : ('')}
                      {siteData && siteData.club_inner[0].social_linkedin_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_linkedin_link} target="_blank"><LinkedInIcon /></Link>
                      ) : ('')}
                      {siteData && siteData.club_inner[0].social_pintrest_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_pintrest_link} target="_blank"><PinterestIcon /></Link>
                      ) : ('')}
                      {siteData && siteData.club_inner[0].social_whatsapp_link ? (
                        <Link href={siteData && siteData.club_inner[0].social_whatsapp_link} target="_blank"><WhatsAppIcon /></Link>
                      ) : ('')}
                    </Stack>
                  ) : ('')}    
                </Container>            
              </Typography>
              </Typography> 
            )}
          </>
        )}
        
    </Fragment>
    
  )
}

export default NavbarTwo