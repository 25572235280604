import React, { Fragment, useState, useEffect, useContext } from "react";
import { Typography, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import Link from "next/link";
import Image from "next/image";
import NavMenuOne from "./menus/NavMenuOne";
import NavMenuTwo from "./menus/NavMenuTwo";
import TRDesign3 from "./tableReservations/TRDesign3"
import PageLoader from '@/components/loader';
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';

const renderNavMenu = (type, menu) => {
  switch (type) {
    case 1:
      return <NavMenuOne data={menu} />;
    case 2:
      return <NavMenuTwo data={menu} />;
    default:
      return <NavMenuTwo data={menu} />;
  }
};

function NavbarSix({trData}) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css);
    setLoading(false)
  }, [themeData]);

  const bannerContainer = {
    // background: `url(${
    //   themeData ? themeData.home_banner : ""
    // })`,
    height: "100vh",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    pb: 2,
    "& h2": {
      fontFamily: themeCss
        ? themeCss.theme_primary_font.replace("+", " ")
        : "Poppins",
      fontSize: 22,
      fontWeight: 500,
      textTransform: "uppercase",
      mb: 2,
      letterSpacing: 1,
      color: theme.palette.background.default,
    },   
  };

  return (
    <Fragment>
      {loading ? ( <PageLoader /> ) : (
        <>
          {themeData && themeData.home_banner_type == 1 ? (
            <Typography
              component="div"
              sx={{
                ...bannerContainer,
                background: `url(${
                  themeData && themeData ? themeData.home_banner : ""
                })`,
              }}
            >
              <Container maxWidth="lg">
                <Stack
                  diaplay="flex"
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                >
                  <Link href={"/"}>
                    <Image
                      src={siteData && siteData ? siteData.club_inner[0].logo_path : ""}
                      alt="..."
                      width={100}
                      height={55}
                      sizes="(max-width: 325px) 100vw"
                      style={{objectFit: "contain"}}	
                    />
                  </Link>
                  <Typography component="div" className="desktopMode">                  
                  {renderNavMenu(siteData && siteData.sections[0].navbar_menu_type)}
                  </Typography>
                  <Typography component="div" className="mobileMode">
                    {renderNavMenu(1)}
                  </Typography>
                </Stack>
              </Container>
              {/* ----------------- TABLE RESERVATIONS -------------- */}
              <TRDesign3 themeCss={themeCss} siteData={siteData} trData={trData}/>
            </Typography>
          ) : (
            <Typography component="div" className="myvideo" sx={{...bannerContainer, pb:0}}>
                <video src={themeData && themeData.home_banner_video} autoPlay loop muted playsInline={true} disablePictureInPicture={true} />
                <Typography component="div" className="content">
                  <Container maxWidth="lg">
                  <Stack
                    diaplay="flex"
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <Link href={"/"}>
                      <Image
                        src={siteData && siteData ? siteData.club_inner[0].logo_path : ""}
                        alt="..."
                        width={100}
                        height={55}
                        sizes="(max-width: 325px) 100vw"
                        style={{objectFit: "contain"}}	
                      />
                    </Link>
                    <Typography component="div" className="desktopMode">                  
                    {renderNavMenu(siteData && siteData.sections[0].navbar_menu_type)}
                    </Typography>
                    <Typography component="div" className="mobileMode">
                      {renderNavMenu(1)}
                    </Typography>
                  </Stack>
                  <TRDesign3 
                  themeCss={themeCss} 
                  siteData={siteData} 
                  trData={trData}/>
                </Container>            
              </Typography>
            </Typography>
          )}
        </>
      )}
    </Fragment>
  );
}

export default NavbarSix;
