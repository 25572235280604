import React, {useState, useEffect } from 'react';
import {Grid, Container } from '@mui/material';
import HappyHoursOne from './happyHours/typeOne'
import HappyHoursTwo from './happyHours/typeTwo'
import HappyHoursThree from './happyHours/typeThree'

import FoodOrderOne from './orderFood/typeOne'
import FoodOrderTwo from './orderFood/typeTwo'
import FoodOrderThree from './orderFood/typeThree'
import { useAppContext } from '../AppContext';
import { useTheme } from '@mui/material/styles';


const renderHappyHoursSectionCard = (type, data1) => {
    switch(type){
      case 1: return <HappyHoursOne data={data1} />;
      case 2: return <HappyHoursTwo data={data1} />;
      case 3: return <HappyHoursThree data={data1} />;
      default: return <HappyHoursOne data={data1} />;
    };
};

const renderFoodOrderSectionCard = (type, data2) => {
    switch(type){
        case 1: return <FoodOrderOne data={data2} />;
        case 2: return <FoodOrderTwo data={data2} />;
        case 3: return <FoodOrderThree data={data2} />;
        default: return <FoodOrderOne data={data2} />;
    };
};


const HomeHappyContainer = {
    pt:{md:10, sm:7, xs:5},
};


function HappyHoursFoodOrderSections(props) {

    const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();

    const [loading, setLoading] = useState(true)
    const [themeCss, setThemeCss] = useState(null);

    useEffect(()=>{
        let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
        setThemeCss(custom_css);
    }, [])

  return (
    <Container maxWidth="lg" sx={{...HomeHappyContainer}}>
        <Grid container spacing={siteData&&siteData.sections[0].happy_hours_type ? {md:8, sm:4, xs:2}: {md:5, sm:4, xs:2}}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                {renderHappyHoursSectionCard(siteData&&siteData.sections[0].happy_hours_type, props.data1)}
            </Grid>
            {siteData&&siteData.sections[0].restaurant_food_order_link && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    {renderFoodOrderSectionCard(
                        siteData&&siteData.sections[0].order_food_type,
                        props.data2
                    )}
                </Grid>
            )}
        </Grid>
    </Container>
  )
}

export default HappyHoursFoodOrderSections