import React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function One () {
    return(
        <>
            <Skeleton
                animation="wave"
                variant="rectangular"
                height={200}
                // sx={{  borderRadius: 0 }}
                
            />
            <Skeleton
                animation="wave"
                height={10}
                // sx={{  my:2 }}
                
            />
            <Skeleton
                animation="wave"
                height={10}
                sx={{  my:2 }}
            
            />
        </>
    )
}