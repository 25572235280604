import React from "react";
import Skeleton from "@mui/material/Skeleton";
import {Stack} from '@mui/material';

export default function Two () {
    return(
        <>
            <Skeleton
                animation="wave"
                variant="rectangular"
                height={200}
                sx={{  borderRadius: 1 }}
                
            />
            <Stack display="flex" sx={{margin:'-10px auto', width:'90%'}}>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={100}
                    sx={{  my:2.5, borderRadius:4}}
                />
            </Stack>
        </>
    )
}