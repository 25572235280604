import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  InputAdornment,
  InputLabel,
  Modal,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
// import PersonIcon from "@mui/icons-material/Person";
// import DateIcon from "@mui/icons-material/CalendarToday";
// import TimeIcon from "@mui/icons-material/AccessTime";
import ButtonTypeOne from "../../buttons/TypeOne";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { nanoid } from "nanoid";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import CreateIcon from "@mui/icons-material/Create";
// import KeyIcon from "@mui/icons-material/Key";
// import PhoneInput from "react-phone-number-input";
// import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import axios from "axios";

const tableReservationTimings = [
  {
    id: 1,
    day: "Mon",
    status: 0,
    start_time: "10:00:00",
    end_time: "22:00:00",
  },
  {
    id: 2,
    day: "Tue",
    status: 1,
    start_time: "10:00:00",
    end_time: "22:00:00",
  },
  {
    id: 3,
    day: "Wed",
    status: 1,
    start_time: "18:00:00",
    end_time: "22:00:00",
  },
  {
    id: 4,
    day: "Thu",
    status: 1,
    start_time: "10:00:00",
    end_time: "22:00:00",
  },
  {
    id: 5,
    day: "Fri",
    status: 1,
    start_time: "10:00:00",
    end_time: "22:00:00",
  },
  {
    id: 6,
    day: "Sat",
    status: 1,
    start_time: "10:00:00",
    end_time: "22:00:00",
  },
  {
    id: 7,
    day: "Sun",
    status: 1,
    start_time: "10:00:00",
    end_time: "22:00:00",
  },
];

export default function TRDesign3({ themeCss, siteData, trData }) {
  const formContainerOne = {
    width: "100%",
    minHeight: "400px",
    background: "#fff",
    border: `1px solid #1D1D1D`,
    borderRadius: 5,
    p: {md:3, sm:3, xs:3},
    mt:{md:3, sm:3, xs:3},
    fontFamily: themeCss
      ? themeCss.theme_body_font.replace("+", " ")
      : "Poppins",
  };

  const formWrapperSix = {
    "& h5": {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "Poppins",
      color: "#2A2A2A",
    },
    "& h6": {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "Poppins",
      color: "#2A2A2A",
    },
  };

  const controlLabel = {
    fontWeight: 700,
    fontSize: 14,
    textAlign: "left",
    color: "#2A2A2A",
    fontFamily: themeCss
      ? themeCss.theme_body_font.replace("+", " ")
      : "Poppins",
    alignSelf: "baseline",
    display: "flex",
    flexDirection: "row",
    mt: 3,
    mb: 1,
  };

  const BtnItem = {
    boxShadow:`0px 0px 21px -12px #000`,
    color: "#737373",
    // border: `1px solid #737373`,
    margin: "0 5px",
    p: 0.5,
    minWidth: 50,
    height: 50,
    borderRadius: 2,
    "& h5": {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "Poppins",
    },
    "& h6": {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "Poppins",
    },
  };
  const BtnItemActive = {
    boxShadow:`0px 0px 21px -12px #000`,
    background: themeCss ? themeCss.theme_main_color : "",
    // border: `1px solid ${themeCss ? themeCss.theme_main_color : ""}`,
    color: themeCss ? themeCss.theme_primary_font_color : `#151414`,
    margin: "0 5px",
    p: 0.5,
    minWidth: 50,
    height: 50,
    borderRadius: 2,
    "& h5": {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "Poppins",
      color: themeCss ? themeCss.theme_primary_font_color : `#151414`,
    },
    "& h6": {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: themeCss
        ? themeCss.theme_body_font.replace("+", " ")
        : "Poppins",
      color: themeCss ? themeCss.theme_primary_font_color : `#151414`,
    }
  };

  const getTimeStops = (start, end, y) => {
    let hours = parseInt(start.slice(0, 2));
    let mins = parseInt(start.slice(3, 5));
    const mins2 = Math.round(mins / 30) * 30;
    const startNew = `${hours}:${mins2}`;

    let hours2 = parseInt(end.slice(0, 2));
    let mins21 = parseInt(end.slice(3, 5));
    const mins22 = Math.round(mins21 / 30) * 30;
    const endNew = `${hours2}:${mins22}`;

    var startTime = moment(startNew, "HH:mm");
    var endTime = moment(endNew, "HH:mm");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }

    var timeStops = [];
    const current_time = `${new Date().getHours()}:${new Date().getMinutes()}`;

    while (startTime <= endTime) {
      timeStops.push(new moment(startTime).format("HH:mm"));
      startTime.add(30, "minutes");
    }

    const final = timeStops.filter((e) => {
      if (y == dates[0]) {
        return e >= current_time;
      } else {
        return e;
      }
    });

    final.pop();    

    if (y == dates[0]) {
      if (end.slice(0, 5) >= current_time) {
        final.push(end.slice(0, 5));
      }
    } else {
      final.push(end.slice(0, 5));
    }

    return final;
  };

  const handleSelectedDate = (x) => {
    setDate(x);
    const result = getTimeStops(x.startTime, x.endTime, x);
    settimes(result);
    setTime(result[0] ? result[0] : null);
  };

  const currentDate = new Date();
  const [dates, setdates] = useState([
    {
      date: new Date(currentDate.setDate(currentDate.getDate() + 0)).toString(),
      status: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].status,
      startTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].start_time,
      endTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].end_time,
    },
    {
      date: new Date(currentDate.setDate(currentDate.getDate() + 1)).toString(),
      status: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].status,
      startTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].start_time,
      endTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].end_time,
    },
    {
      date: new Date(currentDate.setDate(currentDate.getDate() + 1)).toString(),
      status: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].status,
      startTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].start_time,
      endTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].end_time,
    },
    {
      date: new Date(currentDate.setDate(currentDate.getDate() + 1)).toString(),
      status: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].status,
      startTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].start_time,
      endTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].end_time,
    },
    {
      date: new Date(currentDate.setDate(currentDate.getDate() + 1)).toString(),
      status: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].status,
      startTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].start_time,
      endTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].end_time,
    },
    {
      date: new Date(currentDate.setDate(currentDate.getDate() + 1)).toString(),
      status: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].status,
      startTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].start_time,
      endTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].end_time,
    },
    {
      date: new Date(currentDate.setDate(currentDate.getDate() + 1)).toString(),
      status: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].status,
      startTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].start_time,
      endTime: tableReservationTimings.filter((e) => {
        return (
          e.day ==
          new Date(currentDate.setDate(currentDate.getDate()))
            .toString()
            .slice(0, 3)
        );
      })[0].end_time,
    },
  ]);
  const [date, setDate] = useState(dates[0]);
  const [times, settimes] = useState(
    getTimeStops(date.startTime, date.endTime, date)
  );

  const [time, setTime] = useState(times[0] ? times[0] : null);
  const [noOfPeople, setNoOfPeople] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comments, setComments] = useState("");
  const [otp, setOtp] = useState("");
  const [showCommentsBox, setShowCommentsBox] = useState(false);

  const [step, setStep] = useState(1);

  const increasePersons = (event) => {
    event.preventDefault();
    if(noOfPeople < 20){
      setNoOfPeople(noOfPeople + 1);
    }
  };

  const toggleCommentsBox = (e) => {
    e.preventDefault();
    setShowCommentsBox(!showCommentsBox);
  };

  const decreasePersons = (event) => {
    event.preventDefault();
    if (noOfPeople > 0) {
      setNoOfPeople(noOfPeople - 1);
    } else {
      setNoOfPeople(0);
    }
  };

  const changeStep = (e, value) => {
    e.preventDefault();    
    if (value == 1) {
      setShowCommentsBox(false);
    }
    setStep(value);
  };


  const stepOne = () => {
    return (
      <>
        <Typography component="div">
          <InputLabel for="selectDate" sx={controlLabel}>
            Select Date
          </InputLabel>
          <ToggleButtonGroup value={date} exclusive sx={{ 
            width: "100%",
            '& .MuiToggleButton-root:hover':{
              background: themeCss ? themeCss.theme_main_color : "",
              border: `1px solid ${themeCss ? themeCss.theme_main_color : ""}`,
              color: themeCss ? themeCss.theme_primary_font_color : `#151414`,
            }
          }}>
            <Swiper
              modules={[Pagination, Autoplay, FreeMode]}
              spaceBetween={5}
              slidesPerView={5.5}
              freeMode={true}
              autoplay={false}
              loop={false}
            >
              {dates.map(
                (e, i) =>
                  e !== undefined && (
                    <SwiperSlide key={nanoid(30)}>
                      <ToggleButton
                        sx={e.date === date.date ? BtnItemActive : BtnItem}
                        value={e.date}
                        onClick={() => handleSelectedDate(e)}
                      >
                        <Stack display="flex" flexDirection={"column"}>
                          <Typography variant="h6">
                            {e.date.slice(0, 3)}
                          </Typography>
                          <Typography variant="h5">
                            {e.date.slice(8, 10)}
                          </Typography>
                        </Stack>
                      </ToggleButton>
                    </SwiperSlide>
                  )
              )}
            </Swiper>
          </ToggleButtonGroup>
        </Typography>

        {date.status == 1 && times.some((e) => e != null) ? (
          <>
            <Typography component="div">
              <InputLabel for="selectTime" sx={controlLabel}>
                Select Time
              </InputLabel>
              <ToggleButtonGroup value={time} exclusive sx={{ 
                width: "100%",
                '& .MuiToggleButton-root:hover':{
                  background: themeCss ? themeCss.theme_main_color : "",
                  border: `1px solid ${themeCss ? themeCss.theme_main_color : ""}`,
                  color: themeCss ? themeCss.theme_primary_font_color : `#151414`,
                }
             }}>
                <Box sx={{ width: "100%", }}>
                  <Swiper
                    modules={[Pagination, Autoplay, FreeMode]}
                    spaceBetween={5}
                    slidesPerView={6}
                    freeMode={true}
                    autoplay={false}
                    loop={false}
                    // breakpoints={{
                    //   768: {
                    //       width: 768,
                    //       slidesPerView: 4,
                    //   },
                    //   425: {
                    //       width: 425,
                    //       slidesPerView: 3.25,
                    //   },
                    // }}
                  >
                    {times.map(
                      (e, i) =>
                        e !== null && (
                          <SwiperSlide key={nanoid(30)}>
                            <ToggleButton
                              sx={time === e ? BtnItemActive : BtnItem}
                              value={e}
                              onClick={() => setTime(e)}
                            >
                              <Typography variant="h6">{e}</Typography>
                            </ToggleButton>
                          </SwiperSlide>
                        )
                    )}
                  </Swiper>
                </Box>
              </ToggleButtonGroup>
            </Typography>

            <Typography component="div">
              <InputLabel for="noOfPersons" sx={controlLabel}>
                No Of People
              </InputLabel>
              <ToggleButtonGroup sx={{
                '& .MuiToggleButtonGroup-grouped:not(:first-of-type)':{
                  borderLeft: `unset`,
                  ml:-0.7
                }
              }}>
                {noOfPeople == 0 ? (
                  <ToggleButton
                    sx={{ ...BtnItem, width: 30, height: 30 }}
                    disabled
                  >
                    -
                  </ToggleButton>
                ) : (
                  <ToggleButton
                    sx={{ ...BtnItem, width: 30, height: 30 }}
                    onClick={decreasePersons}
                  >
                    -
                  </ToggleButton>
                )}
                <ToggleButton
                  sx={{
                    ...BtnItem,
                    width: 30,
                    height: 30,
                    fontWeight: 700,
                  }}
                >
                  {noOfPeople}
                </ToggleButton>
                <ToggleButton
                  sx={{ ...BtnItem, width: 30, height: 30 }}
                  onClick={increasePersons}
                >
                  +
                </ToggleButton>
              </ToggleButtonGroup>
            </Typography>

            <Typography
              component="div"
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 5,
                mb: 3,
              }}
              onClick={(e) => changeStep(e, 2)}
            >
              <ButtonTypeOne text="Next" link="" borderRadius={2} />
            </Typography>
          </>
        ) : (
          <Typography
            variant="h6"
            sx={{ 
              textAlign: "center", 
              my: 10, 
              color: "#1e1e1e", 
              fontFamily: themeCss
              ? themeCss.theme_body_font.replace("+", " ")
              : "Poppins",
              fontSize:14
           }}
          >
            No tables available on this day
          </Typography>
        )}
      </>
    );
  };

  const stepTwo = () => {
    return (
      <Fragment>
        <Stack
          sx={formWrapperSix}
          diaplay="flex"
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Typography variant="h6">
            {date.date.slice(0, 10)}&nbsp;&nbsp;|&nbsp;&nbsp;
          </Typography>
          <Typography variant="h6">{time}&nbsp;&nbsp;|&nbsp;&nbsp;</Typography>
          <Typography variant="h6">{noOfPeople} Person(s)</Typography>
        </Stack>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { width: "99%" },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography component="div">
            <InputLabel for="name" sx={{ ...controlLabel, mt: 1 }}>
              Name
            </InputLabel>
            <TextField
              id="name"
              label=""
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{
                // ...InputStyle,
                "& input ": {
                  p: `10px !important`,
                  width:'100%',
                  fontSize: `14px !important`,
                  fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " "): "Poppins",
                },
                "& .MuiOutlinedInput-root":{
                  '& .MuiOutlinedInput-notchedOutline':{
                    borderColor: '#c4c4c4'
                  },
                },
                "& .MuiOutlinedInput-root:hover":{
                  '& .MuiOutlinedInput-notchedOutline':{
                    borderColor: '#c4c4c4'
                  },
                },
                "& .MuiOutlinedInput-root:active":{
                  '& .MuiOutlinedInput-notchedOutline':{
                    borderColor: '#c4c4c4'
                  },
                }
              }}
            />
          </Typography>
          <Typography component="div">
            <InputLabel for="email" sx={{ ...controlLabel, mt: 1 }}>
              Email
            </InputLabel>
            <TextField
              id="email"
              label=""
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                // ...InputStyle,
                "& input ": {
                  p: `10px !important`,
                  width:'100%',
                  fontSize: `14px !important`,
                  fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " "): "Poppins",
                },
                "& .MuiOutlinedInput-root":{
                  '& .MuiOutlinedInput-notchedOutline':{
                    borderColor: '#c4c4c4'
                  },
                },
                "& .MuiOutlinedInput-root:hover":{
                  '& .MuiOutlinedInput-notchedOutline':{
                    borderColor: '#c4c4c4'
                  },
                },
                "& .MuiOutlinedInput-root:active":{
                  '& .MuiOutlinedInput-notchedOutline':{
                    borderColor: '#c4c4c4'
                  },
                }
              }}
            />
          </Typography>
          <Typography component="div">
            <InputLabel for="mobile" sx={{ ...controlLabel, mt: 1 }}>
              Mobile
            </InputLabel>
            {/* <TextField id="mobile" label="" variant="outlined" value={phone} onChange={(e) => setPhone(e.target.value)} /> */}
            {/* <Box className="mobile_field_light_2" sx={{ mr: 2 }}>
              <PhoneInput
                international={true}
                withCountryCallingCode={true}
                limitMaxLength={true}
                countryCallingCodeEditable={false}
                defaultCountry="IN"
                smartCaret={true}
                placeholder="Mobile"
                value={phone}
                onChange={setPhone}
              />
            </Box> */}
            <TextField
              id="phone"
              label=""
              variant="outlined"
              value={phone}
              type="number"
              min="1"
              onChange={(e) => setPhone(e.target.value)}
              sx={{
                // ...InputStyle,
                "& input ": {
                  p: `10px !important`,
                  width:'100%',
                  fontSize: `14px !important`,
                  fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " "): "Poppins",
                },
                "& .MuiOutlinedInput-root":{
                  '& .MuiOutlinedInput-notchedOutline':{
                    borderColor: '#c4c4c4'
                  },
                },
                "& .MuiOutlinedInput-root:hover":{
                  '& .MuiOutlinedInput-notchedOutline':{
                    borderColor: '#c4c4c4'
                  },
                },
                "& .MuiOutlinedInput-root:active":{
                  '& .MuiOutlinedInput-notchedOutline':{
                    borderColor: '#c4c4c4'
                  },
                }
              }}
            />
          </Typography>
          <Typography component="div">
          {!showCommentsBox ? (
            <InputLabel
              for="mobile"
              sx={{ ...controlLabel, mt: 1, cursor: "pointer", alignItems:'center'}}
              onClick={toggleCommentsBox}
            >
              <AddIcon
                sx={{
                  fontSize: 13,
                  color: themeCss ? themeCss.theme_main_color : `#151414`,
                }}
              />
              &nbsp;Special Instructions
            </InputLabel>
            ):(
              <>
              <InputLabel for="instructions" sx={{ ...controlLabel, mt: 1 }}>
                Special Instructions
              </InputLabel>
              <TextField
                id="instructions"
                label=""
                variant="outlined"
                multiline
                maxRows={3}
                // sx={{ mb: 2 }}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                sx={{
                  mb:2,
                  // ...InputStyle,
                  "& input ": {
                    p: `10px !important`,
                    width:'100%',
                    fontSize: `14px !important`,
                    fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " "): "Poppins",
                  },
                  "& .MuiOutlinedInput-root":{
                    '& .MuiOutlinedInput-notchedOutline':{
                      borderColor: '#c4c4c4'
                    },
                  },
                  "& .MuiOutlinedInput-root:hover":{
                    '& .MuiOutlinedInput-notchedOutline':{
                      borderColor: '#c4c4c4'
                    },
                  },
                  "& .MuiOutlinedInput-root:active":{
                    '& .MuiOutlinedInput-notchedOutline':{
                      borderColor: '#c4c4c4'
                    },
                  }
                }}
              />
              </>
            )}
          </Typography>
          <Typography
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 1,
            }}
          >
            <InputLabel
              sx={{ ...controlLabel, mt: 1, cursor: "pointer", alignItems:'center' }}
              onClick={(e) => changeStep(e, 1)}
            >
              <KeyboardBackspaceIcon
                sx={{
                  fontSize: 13,
                  color: themeCss
                    ? themeCss.theme_main_color
                    : `#151414`,
                }}
              />
              &nbsp;Back
            </InputLabel>
            <Box onClick={handleOpen}>
              <ButtonTypeOne text="Next" link="" borderRadius={2} />
            </Box>
          </Typography>
        </Box>
        {timeError.state && (
          <Typography
            variant="p"
            sx={{ mt: 3, display: "block", fontSize: "13px", color: "red" }}
          >
            {timeError.msg}
          </Typography>
        )}
      </Fragment>
    );
  };

  const [open, setOpen] = useState(false);
  const [timer, settimer] = useState(61);
  const [showResendOtp, setshowResendOtp] = useState(false);
  const [submitDisabled, setsubmitDisabled] = useState(false);
  const [showForm, setshowForm] = useState(true);
  const [submitError, setsubmitError] = useState({
    state: false,
    msg: "",
  });
  const [timeError, settimeError] = useState({
    state: false,
    msg: "",
  });

  const handleOpen = (e) => {
    e.preventDefault();

    const mainLogic = () => {
      settimeError({
        state: false,
        msg: "",
      });
      setOpen(true);
      settimer(60);

      axios
        .get(`/api/homePage/otp/send?mobile=${phone.replace("+", "")}`)
        .then((response) => {
          if (response.data.type == "success") {
            // console.log("otp sent");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    // selectedTime = '15:57'
    const today = new Date().toISOString().slice(0, 10);
    const now = `${new Date().getHours()}:${new Date().getMinutes()}`;

    if (name === "") {
      settimeError({
        state: true,
        msg: "Please enter a valid name",
      });
    } else if (phone.length < 5) {
      settimeError({
        state: true,
        msg: "Please enter a valid mobile number",
      });
    } else if (today == new Date(date.date).toISOString().slice(0, 10)) {
      if (time < now) {
        settimeError({
          state: true,
          msg: "Please select a valid time",
        });
        // console.log("here-----------------");
      } else {
        mainLogic();
      }
    } else {
      mainLogic();
    }
  };

  const resendOtp = (e) => {
    axios
      .get(`/api/homePage/otp/send?mobile=${phone.replace("+", "")}`)
      .then((response) => {
        if (response.data.type == "success") {
          setsubmitDisabled(false);
          // console.log("otp sent");
          settimer(60);
          setshowResendOtp(false);
        }
      })
      .catch((err) => {
        setsubmitDisabled(false);
        console.log(err);
      });
  };

  const handleClose = () => setOpen(false);

  const submitOtp = (e) => {
    e.preventDefault();
    setsubmitError({
      state: false,
      msg: "",
    });
    setsubmitDisabled(true);

    const whiteLabelUrl = window.location.host;

    axios
      .get(
        `/api/homePage/otp/verify?otp=${otp}&mobile=${phone.replace("+", "")}`
      )
      .then((response) => {
        if (response.data.type == "success") {
          axios
            .post(
              "/api/homePage/submitTableReservationFree",
              {
                whiteLabelUrl,
                name,
                email,
                phone,
                date: new Date(date.date).toISOString().slice(0, 10),
                time,
                noOfPersons: noOfPeople,
                comments,
              },
              {
                headers: {
                  "x-auth-token": process.env.JWT_TOKEN,
                },
              }
            )
            .then((resp) => {
              setshowForm(false);
              handleClose();
              // window.scrollTo(0, 0)
            })
            .catch((err) => {
              console.log(err);
              if (err.response) {
                setsubmitError({
                  state: true,
                  msg: err.response.data,
                });
                setsubmitDisabled(false);
              }
            });
        } else {
          setsubmitDisabled(false);
          setsubmitError({
            state: true,
            msg: "Invalid OTP entered",
          });
        }
      })
      .catch((err) => {
        setsubmitDisabled(false);
        console.log(err);
      });
  };

  useEffect(() => {
    open &&
      (timer > 0
        ? setTimeout(() => settimer(timer - 1), 1000)
        : setshowResendOtp(true));
  }, [timer]);

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          diaplay="flex"
          flexDirection={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Typography component="div" sx={{ width: {md:'40%', sm:'100%', xs:'100%'} }}>
            <Box
              component="form"
              sx={{
                ...formContainerOne,
                "& .MuiTextField-root": { width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <Typography variant="h2" sx={{ textAlign: "left" }}>
                Book a table
              </Typography>

              {showForm ? (
                <>
                  {step === 1 && stepOne()}
                  {step === 2 && stepTwo()}
                </>
              ) : (
                <Typography
                  variant="p"
                  sx={{
                    my: 3,
                    display: "block",
                    fontSize: "13px",
                    color: "green",
                  }}
                >
                  Thank you for submitting. We will get back to you with the
                  confirmation soon.
                </Typography>
              )}
            </Box>
          </Typography>
        </Stack>
      </Container>

      {/*OTP Model */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          m: {
            xs: 2,
          },
        }}
        className="onelink_otp_modal"
      >
        <>
          <Box
            // sx={formWrapperSix}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              color: "#000",
              ...formWrapperSix
            }}
            // diaplay="flex"
            // flexDirection={"row"}
            // justifyContent={"flex-start"}
            // alignItems={"center"}
          >
            <Box sx={{ 
              display: "flex", 
              alignItems: "center",
              fontSize: `14px !important`,
              justifyContent:'center',
              fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " "): "Poppins" 
            }}>
              <Typography variant="h5">
                {date.date.slice(0, 10)}&nbsp;&nbsp;|&nbsp;&nbsp;
              </Typography>
              <Typography variant="h5">
                {time}&nbsp;&nbsp;|&nbsp;&nbsp;
              </Typography>
              <Typography variant="h5">{noOfPeople} Person(s)</Typography>
            </Box>

            <Box sx={{ mt: 4 }}>
              <Typography component="div">
                <InputLabel for="otp" sx={{ ...controlLabel, mt: 1 }}>
                  OTP
                </InputLabel>
                <TextField
                  fullWidth
                  id="otp"
                  // label="OTP"
                  variant="outlined"
                  value={otp}
                  type="number"
                  onChange={(e) => setOtp(e.target.value)}
                  sx={{
                    mb:2,
                    // ...InputStyle,
                    "& input ": {
                      p: `10px !important`,
                      width:'100%',
                      fontSize: `14px !important`,
                      fontFamily: themeCss ? themeCss.theme_body_font.replace("+", " "): "Poppins",
                    },
                    "& .MuiOutlinedInput-root":{
                      '& .MuiOutlinedInput-notchedOutline':{
                        borderColor: '#c4c4c4'
                      },
                    },
                    "& .MuiOutlinedInput-root:hover":{
                      '& .MuiOutlinedInput-notchedOutline':{
                        borderColor: '#c4c4c4'
                      },
                    },
                    "& .MuiOutlinedInput-root:active":{
                      '& .MuiOutlinedInput-notchedOutline':{
                        borderColor: '#c4c4c4'
                      },
                    }
                  }}
                  
                />
              </Typography>

              <Typography
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  mb: 1,
                }}
              >
                <InputLabel
                  sx={{ ...controlLabel, mt: 1, cursor: "pointer" }}
                  onClick={(e) => handleClose()}
                >
                  <KeyboardBackspaceIcon
                    sx={{
                      fontSize: 13,
                      color: themeCss
                        ? themeCss.theme_main_color
                        : `#151414`,
                    }}
                  />
                  &nbsp;Back
                </InputLabel>
                <Box onClick={submitOtp}>
                  <ButtonTypeOne text="Submit" link="" borderRadius={2} />
                </Box>
              </Typography>
              {showResendOtp ? (
                <Typography
                  variant="text"
                  sx={{ color: "#000", fontSize: "10px", cursor: "pointer" }}
                  onClick={() => resendOtp()}
                >
                  Resend
                </Typography>
              ) : (
                <Typography
                  variant="text"
                  sx={{
                    fontFamily: "DM Sans",
                    color: "#000",
                    fontSize: "10px",
                  }}
                >
                  {timer}
                </Typography>
              )}
            </Box>
            {submitError.state && (
              <Typography
                variant="p"
                sx={{ mt: 3, display: "block", fontSize: "13px", color: "red" }}
              >
                {submitError.msg}
              </Typography>
            )}
          </Box>
        </>
      </Modal>
    </>
  );
}
