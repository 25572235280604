import React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function Two () {
    return(
        <Skeleton
            animation="wave"
            variant="rectangular"
            height={250}
            sx={{  borderRadius: 3 }}
        />
    )
}