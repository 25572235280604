import React, {useState, useEffect} from 'react';
import CallMadeIcon from '@mui/icons-material/CallMade';
import Link from 'next/link'
import { Typography } from '@mui/material';
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';


function TypeOne({text, link}) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();

  const [loading, setLoading] = useState(true)
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
      let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
      setThemeCss(custom_css);
  }, [themeData])
  

  const ButtonStyle = {
    '& a':{
      fontFamily: themeCss ? themeCss.theme_body_font.replace("+"," ") : '',
      borderRadius:0,
      background: theme.palette.background.default,
      color:themeCss ? themeCss.theme_main_color: ``,
      textDecoration:`none`,
      textTransform:`uppercase`,
      minWidth:100,
      fontSize: 18,
      fontWeight:500,
      px:{md:4, sm:3, xs:2},
      py:{md:2.5, sm:2, xs:1.5},
      '&:hover':{
        background: theme.palette.background.default,
        color:themeCss ? themeCss.theme_main_color: ``,
        textDecoration:`none`,
        opacity:0.9,
      }
    }
};

  return (
    <Typography component="div" sx={ButtonStyle} >
      <Link href={link} variant="contained" >
        <>
          {text}
          <CallMadeIcon style={{marginLeft:'15px', fontSize:16}} />
        </>
      </Link>
    </Typography>    
  )
}

export default TypeOne