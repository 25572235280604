import React, {useState, useEffect, useContext, Fragment} from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Image from 'next/image'
import OrderFoodImage from '../../public/images/menu.png'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Link from 'next/link'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';


export default function TypeThree(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);


  const MenuCardThree = {
    display:'flex',
    alignItems:'center',
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background: theme.palette.background.alternate,
    borderRadius:4,
    border:`1px solid ${theme.palette.background.borderColor}`,
    p:1,
    boxShadow: theme.shadows[0],
    transition:`0.5s`,
    minHeight: 120,
    "&:hover":{
      transition:`0.5s`,
      boxShadow: theme.shadows[1],
      background: theme.palette.background.alternate,
    },
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color: theme.palette.primary.contrastText,
      mb:0,
      mt:0,
    },
    '& h5':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:18,
      fontWeight:500,
      color: theme.palette.primary.contrastText,
    }
}


const IconStyle = {
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    p:0.5,
    "& img":{
      borderRadius:2,
      width:'75px',
      height:'75px'
    }
};

  return (
    <Fragment>
      {props.data.restaurant_food_order_link ? (
        <Link href={props.data.restaurant_food_order_link ? props.data.restaurant_food_order_link : ''} target="_blank">
          <Card sx={MenuCardThree}>
            <CardMedia sx={IconStyle}>
                <Image 
                  src={OrderFoodImage} 
                  alt="" 
                  width={30} 
                  height={40} 
                  sizes="(max-width: 325px) 100vw"
                  style={{objectFit: "contain"}}	
                />
            </CardMedia>
            <CardContent sx={{py:0,pb:`0px !important`,width:'100%'}}>
              <Stack display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="h3">Order Food</Typography>
                  <ArrowRightAltIcon sx={{color:themeCss ? themeCss.theme_main_color: ``}}/>
              </Stack>
            </CardContent>
          </Card>
        </Link>
      ):('')}
    </Fragment>
  );
}
