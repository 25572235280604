import React, { Fragment, useEffect, useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Typography } from '@mui/material';

function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

export default function GalleryOne(props) {

  const[ itemData, setItemData ] = useState(null);
  const[ isLoading, setIsLoading ] = useState(true);

  useEffect(()=>{
    let arr = [];
    
    for(let i=0;i<props.data.length;i++){
      let new_list = new Object();
      new_list.id = props.data[i].id
      new_list.img_path = props.data[i].img_path
      if(i==0 || i==5 || i==8){
        new_list.rows = 2
        new_list.cols = 2
      }

      if(i==3 || i==4 || i==9){
        new_list.cols = 2
      }
      arr.push(new_list)
    }
    setItemData(arr)
    setIsLoading(false)
  },[props.data])

  return (
    <Fragment>
      {!isLoading ? (
        <Typography component="div">
        <ImageList
          sx={{}}
          variant="quilted"
          cols={4}
          rowHeight={250}
        >
          {itemData && itemData.map((item) => (
            <ImageListItem key={item.img_path} cols={item.cols || 1} rows={item.rows || 1}>
              <img
                {...srcset(item.img_path, 250, item.rows, item.cols)}
                alt={item.title}
                title={item.title}
                loading="lazy"
                style={{padding:'10px'}}
              />
            </ImageListItem>
          ))}
        </ImageList>
        </Typography>
      ):('loading')}
    </Fragment>
  );
}
