import React, {useState, useEffect, useContext} from 'react';
import { Container, Typography, Grid } from '@mui/material';
import AltButtonTypeOne from '../../components/buttons/AltTypeOne'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';

export default function ThemeOne(props) {



  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);
    
    const HomeBreakContainer = {
        fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
        py:{md:10, sm:7, xs:5},
        mt:10,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        background: themeCss ? themeCss.theme_main_color: ``,
        '& h2':{
          fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
           fontSize:{md:60, sm:45, xs:40},
           fontWeight:700,
           textTransform:'uppercase',
           color:`${themeCss ? themeCss.theme_primary_font_color : '#000'}`,
           margin:0,
           marginBottom:5,
        }
    };
  return (
    <Typography component="div" sx={HomeBreakContainer}>
        <Container maxWidth="md" align="center">
            <h2>Looking for <br/>corporate party</h2>
            <AltButtonTypeOne text="Contact Us" link={'/contact'} />
        </Container>
    </Typography>
  )
}