import React, { useState, useEffect } from "react";
import NavbarOne from "../navbars/NavbarOne";
import NavbarTwo from "../navbars/NavbarTwo";
import NavbarThree from "../navbars/NavbarThree";
import NavbarFour from "../navbars/NavbarFour";
import NavbarFive from "../navbars/NavbarFive";
import NavbarSix from "../navbars/NavbarSix";
import moment from "moment";
import axios from "axios";
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import Head from "next/head";

const renderNavbar = (type, menu, trData) => {
  switch (type) {
    case 1:
      return <NavbarOne />;
    case 2:
      return <NavbarTwo />;
    case 3:
      return <NavbarThree />;
    case 4:
      return <NavbarFour data={menu} trData={trData} />;
    case 5:
      return <NavbarFive data={menu} trData={trData} />;
    case 6:
      return <NavbarSix data={menu} trData={trData} />;
    default:
      return <NavbarOne />;
  }
};

export default function Header() {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css)
    setLoading(false)
  }, [themeData]);
  

  // TABLE RESERVATION RELATED STATE VARIABLES:
  const [date, setDate] = useState(moment());
  const [time, setTime] = useState(moment());
  const [noOfPersons, setNoOfPersons] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comments, setComments] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState({
    msg: "",
    state: false,
  });
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const sendOtp = () => {
    axios
      .get(`/api/homePage/otp/send?mobile=${phone.replace('+', '')}`)
      .then((response) => {
        if (response.data.type == "success") {          
        }
      })
      .catch((err) => {
        console.log(err);
        setError({
          msg: 'Could Not Submit',
          state: true
        })
      });
  };

  const verifyOtp = () => {
    setSubmitting(true)
    const whiteLabelUrl = window.location.host
    // console.log('here--------------------------------');
    axios
      .get(`/api/homePage/otp/verify?otp=${otp}&mobile=${phone.replace('+', '')}`)
      .then((response) => {
        // console.log(response.data);
        if (response.data.type == "success") {

          axios.post('/api/homePage/submitTableReservationFree', {
            whiteLabelUrl,
            name,
            email,
            phone,
            date: date.toISOString().slice(0, 10),
            // time: time.toISOString().split('T')[1].slice(0, 5),
            time,
            noOfPersons,
            comments
          }, {
            headers: {
              'x-auth-token': process.env.JWT_TOKEN
            }
          })
          .then(resp => {
            setSubmitting(false)
            // console.log("otp verified");
            setSuccess(true)
          })
          .catch(err => {
            setSubmitting(false)
            console.log(err);
            setError({
              msg: 'Could Not SUbmit',
              state: true
            })
          })

        } else {
          setSubmitting(false)
          setError({
            msg: 'Incorrect OTP',
            state: true
          })
        }
      })
      .catch((err) => {
        setSubmitting(false)
        console.log(err);
      });
  };
 

  return (
    <>
    <Head>
      <link rel="icon" type="image/x-icon" href={siteData && siteData ? siteData.club_inner[0].logo_path : ""} />
    </Head>
      {renderNavbar(
        siteData && siteData.sections[0].navbar_type,
        "",
        {
          date,
          setDate,
          timeOriginal: time,
          setTimeOriginal: setTime,
          noOfPersons,
          setNoOfPersons,
          name,
          setName,
          email,
          setEmail,
          phone,
          setPhone,
          comments,
          setComments,
          error,
          setError,
          otp,
          setOtp,
          success,
          setSuccess,
          sendOtp,
          verifyOtp,
          submitting
        }
      )} 
    </>
  );
}
