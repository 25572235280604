import React, {useState, useEffect, useContext} from 'react';
import { Container, Typography, Stack } from '@mui/material';
import GalleryOne from './galleries/GalleryOne';
import GalleryTwo from './galleries/GalleryTwo';
import GalleryThree from './galleries/GalleryThree';
import Image from 'next/image'
import { nanoid } from 'nanoid';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Link from 'next/link';
import { useAppContext } from '../AppContext';
import { useTheme } from '@mui/material/styles';

const renderGalleryCard = (type, gallery) => {
  switch(type){
    case 1: return <GalleryOne data={gallery}/>;
    case 2: return <GalleryTwo data={gallery}/>;
    case 3: return <GalleryThree data={gallery}/>;
    default: return <GalleryOne data={gallery}/>;
  };
};

export default function HomeGalleries(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const HomeGalleryContainer = {
    pt:{md:10, sm:7, xs:5},
    '& h2':{
       fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
       fontSize:35,
       fontWeight:700,
       color: theme.palette.primary.title
    },
    '& a':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:14,
      fontWeight:400,
      color: themeCss ? themeCss.theme_primary_font_color : "",
      "&:hover":{
        color: themeCss ? themeCss.theme_main_color : "",
      }
    }
   };

   const mobileImageList = {
    'img':{
      width:'100%',
      height:'250px',
      p:0,
      border:`1px solid #5D5D5D`,
      borderRadius:4,
    }
   }
    
  return (
    <Container maxWidth="lg" sx={HomeGalleryContainer}>
        {siteData && (
          <>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h2">Gallery</Typography>
              <Link href={`/gallery`} >View All</Link>
            </Stack>
            <Typography component="div" className="desktopMode">
              {renderGalleryCard(siteData && siteData.sections[0].gallery_type, props.data)}
            </Typography>
            <Typography component="div" sx={{mt:4}} className="mobileMode">
              <Typography component="div" sx={mobileImageList}>
              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={20}                                                   
                navigation                        
                autoplay={true}
                loop={true}
                slidesPerView={1.75}
                breakpoints={{
                  768: {
                      width: 768,
                      slidesPerView: 2,
                  },
                  425: {
                      width: 425,
                      slidesPerView: 1.25,
                  },
                }}
              >
                {siteData && siteData.restaurent_images.map((item)=>(
                  <SwiperSlide key={nanoid(30)}>
                    <Image 
                      src={item.img_path} 
                      alt="..." 
                      width="100" 
                      height="100"
                      sizes="(max-width: 325px) 100vw"
                      style={{objectFit: "cover"}}	
                    />
                  </SwiperSlide>
                ))}
                </Swiper>
              </Typography>
            </Typography>
          </>
        )}
    </Container>

  )
}