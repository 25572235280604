import React, {useState, useEffect, useContext} from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import Image from 'next/image'
import OrderFoodImage from '../../public/images/menu.png'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';


export default function TypeTwo(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData ? JSON.parse(themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const MenuCardTwo = {
    display:'flex',
    alignItems:'center',
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background:theme.palette.background.alternate,
    borderRadius:4,
    border:`1px solid ${theme.palette.background.borderColor}`,
    p:0,
    minHeight: 120,
    ml:{md:0, sm:3, xs:3},
    mb:2,
    boxShadow: theme.shadows[0],
    transition:`0.5s`,
    "&:hover":{
      transition:`0.5s`,
      boxShadow: theme.shadows[0],
      background: theme.palette.background.alternate,
    },
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color:theme.palette.primary.contrastText,
      mb:0,
      mt:0,
      pl:7,
    },
    '& h5':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      fontSize:18,
      fontWeight:500,
      color:theme.palette.primary.contrastText,
    },
    '& h6':{
        fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
        fontSize:14,
        fontWeight:100,
        olor:theme.palette.primary.contrastText,
        mb:0,
        mt:0,
        pl:7,
        display:'flex',
        alignItems:'center'
      }
}

const IconStyle = {
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    borderRadius:1,
    background:themeCss ? themeCss.theme_main_color: ``,
    color:theme.palette.primary.contrastText,
    textDecoration:`none`,
    fontSize: 12,
    fontWeight:500,
    p:0.4,
    width:'25px',
    height:'25px'
};

const imageStyle = {
  fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
  borderRadius:10,
  color:theme.palette.primary.contrastText,
  textDecoration:`none`,
  fontSize: 14,
  fontWeight:500,
  p:0,
  ml:-4,
  position:'absolute',
  "& img":{
    borderRadius:'50%',
    width:'75px',
    height:'75px'
  }
};

  return (
    <Card sx={MenuCardTwo}>
      <Typography component="div" sx={imageStyle}>
        <Image src={OrderFoodImage} alt="" 
          width={30} 
          height={40} 
          sizes="(max-width: 325px) 100vw"
          style={{objectFit: "contain"}}	
        />
      </Typography>
      <CardContent sx={{py:0,pb:`0px !important`,width:'100%'}}>
        <Typography variant="h3">Happy Hours</Typography>
        <Typography component="div" sx={{width:'100%'}}>
          <Stack display="flex" flexDirection="row" justifyContent="space-between" sx={{mt:2}}>
            <Typography variant="h6">
              <CalendarIcon sx={{...IconStyle}} />&nbsp;&nbsp;{props.data && props.data.happy_hours}
            </Typography>
          </Stack>
        </Typography>
      </CardContent>
    </Card>
  );
}
