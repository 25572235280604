import React, {useState, useEffect, useContext} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MenuImage from '../../public/images/menu.png'
import Image from 'next/image'
import Link from 'next/link'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';

export default function MenuOne({title, data1, data2}) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const MenuCardOne = {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    borderRadius:0,
    width:'100%',
    mb:2,
    border:`1px solid ${theme.palette.background.borderColor}`,
    boxShadow: theme.shadows[0],
    transition:`0.5s`,
    "&:hover":{
      boxShadow: theme.shadows[1],
      transition:`0.5s`,
      background: theme.palette.background.alternate
    },
    '& img':{
        width:'100%',
        height:250,
        boxShadow: theme.shadows[0],
    },
    '& h3':{
        textAlign:'center',
        fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
        fontSize:24,
        fontWeight:500,
        color:theme.palette.primary.contrastText,
        py:3,
        textTransform:'capitalize'
    }
}


  return (
    <Link href={`/menus`} >
      <Grid container spacing={0} sx={MenuCardOne}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignSelf="center" textAlign="left">
        {title == 'food menu' ? ( 
          <Image 
          src={data1 ? data1 && data1[0].img_path : MenuImage} 
          width="50" 
          height="50"
          alt="..." 
          sizes="(max-width: 325px) 100vw"
          style={{objectFit: "cover"}}	
        
        />
        ):(
          <Image 
            src={data2 ? data2 && data2[0].img_path : MenuImage} 
            width="50" 
            height="50" 
            alt="..."
            sizes="(max-width: 325px) 100vw"
            style={{objectFit: "cover"}}	  
          />
        )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignSelf="center">
          <Typography variant="h3">{title}</Typography>
        </Grid>
      </Grid>
    </Link>     
  );
}
