import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, Grid, Stack } from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import Link from 'next/link'
import MenuImage from '../../public/images/menu.png'
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';

export default function MenuOne({title, data1, data2}) {

  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css)
  }, [themeData]);

  const MenuCardOne = {
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'center',
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
    background: theme.palette.background.alternate,
    borderRadius:0,
    border:`1px solid ${theme.palette.background.borderColor}`,
    p:2,
    mb:2,
    boxShadow: theme.shadows[0],
    transition:`0.5s`,
    "&:hover":{
      boxShadow: theme.shadows[1],
      transition:`0.5s`,
    },
    '& h3':{
      fontFamily: themeCss ? (themeCss.theme_primary_font).replace("+"," ") : '',
      fontSize:24,
      fontWeight:500,
      color:theme.palette.primary.contrastText,
      textTransform:'capitalize'
    }
}


const ButtonStyle = {
    fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ").replace("+"," ") : '',
    borderRadius:0,
    background:themeCss ? themeCss.theme_main_color: ``,
    color: theme.palette.background.default,
    textDecoration:`none`,
    fontSize: 14,
    fontWeight:500,
    px:2.5,
    py:1.5,
    height:60,
    '&:hover':{
      background:themeCss ? themeCss.theme_main_color: ``,
      textDecoration:`none`,
      opacity:0.9,
    }
};

  return (
    <Link href={`/menus`} >
      <Card sx={MenuCardOne}>
        <CardContent sx={{width:'100%',px:0, pl:2, py:0, pb:`0 !important`}}>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h3">{title}</Typography>
            <Button variant="contained" sx={{...ButtonStyle, width:50}} >
              <CallMadeIcon />
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Link>
  );
}
